import { Container, Statistic } from "semantic-ui-react";
import { pageStyle } from "./constants";
export default function SummaryTab({ applications, users }) {
  return (
    <Container style={pageStyle.mainContainer}>
      <Statistic value={applications.length} label={"Total applications"} />
      <Statistic value={users.length} label={"Total users"} />
    </Container>
  );
}
