import React from "react";
import { Card, Container, Grid, Image, Label, Button } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import { Storage, DataStore } from "aws-amplify";
import { User } from "./models";
import { Embed } from "semantic-ui-react";
import { pageStyle } from "./constants";
import ProfilePhoto from "./components/ProfilePhoto";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  media: {
    height: window.innerWidth / 4,
    width: window.innerWidth / 4,
  },
});
function JobItem({ job }) {
  const navigation = useNavigate();
  const [videoUrl, setVideoUrl] = React.useState("");
  const [owner, setOwner] = React.useState();
  React.useEffect(() => {
    const fetchVideo = async () => {
      const response = await Storage.get(job.video.key.replace(/public\//, ""));
      setVideoUrl(response);
    };
    const fetchOwner = async () => {
      const fetchedInfo = await DataStore.query(User, job.jobOwnerId);
      setOwner(fetchedInfo);
    };
    if (job.video && job.video.key) {
      fetchOwner();
      fetchVideo();
    }
  }, [job.video, job.jobOwnerId]);
  return (
    <Card>
      <video
        contextMenu={"hidden"}
        height={window.screen.availHeight / 3}
        controls
        src={videoUrl}
      />
      <Card.Content>
        <Card.Header>{job.service}</Card.Header>
        <Card.Meta>
          <span className="date">{new Date(job.createdAt).toDateString()}</span>
        </Card.Meta>
        <Card.Description>
          <Label as={"a"} image>
            <ProfilePhoto square user={owner} />

            {owner ? [owner.firstName, owner.lastName].join(" ") : "-"}
          </Label>

          <p>{[job.city, job.state].join(", ")}</p>
          <p>{owner ? [owner.email, owner.phoneNumber].join(" ") : "-"}</p>
          <p>{job.description + "\n\n" + (job.details ? job.details : "-")}</p>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          onClick={() => {
            navigation(`/job/${job.id}`);
          }}
          fluid
        >
          View details
        </Button>
      </Card.Content>
    </Card>
    // <Card key={videoUrl + job.id}>
    //   <CardActionArea>
    //     <video width={"400"} height={"400"} controls src={videoUrl} />
    //     {/* <CardMedia
    //       component={"video"}
    //       className={classes.media}
    //       src={videoUrl}
    //       title="Contemplative Reptile"
    //       controls={true}
    //     /> */}
    //     <CardContent>
    //       <Typography gutterBottom variant="h5" component="h2">
    //         {job.service} - {new Date(job.createdAt).toDateString()}
    //       </Typography>
    //       <Typography variant="body2" component="p">
    //         {owner ? [owner.firstName, owner.lastName].join(" ") : "-"}
    //       </Typography>

    //       <Typography variant="body2" component="p">
    //         {[job.city, job.state].join(", ")}
    //       </Typography>
    //       <Typography variant="body2" component="p">
    //         {owner ? [owner.email, owner.phoneNumber].join(" ") : "-"}
    //       </Typography>
    //       <Typography variant="body2" color="textSecondary" component="p">
    //         {job.description + "\n\n" + (job.details ? job.details : "-")}
    //       </Typography>
    //     </CardContent>
    //   </CardActionArea>
    //   {/* <CardActions>
    //                   <Button size="small" color="primary">
    //                     Share
    //                   </Button>
    //                   <Button size="small" color="primary">
    //                     Learn More
    //                   </Button>
    //                 </CardActions> */}
    // </Card>
  );
}
export default function JobsTab({ jobs }) {
  const classes = useStyles();

  return (
    <Container fluid style={pageStyle.mainContainer}>
      <Grid stackable centered verticalAlign="middle" columns={3}>
        {jobs.map((job) => (
          <Grid.Column key={job.id}>
            <JobItem job={job} />
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  );
}
