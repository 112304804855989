import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { Image } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

const ProfilePhoto = ({ size, user, square, overrideSize, notification }) => {
  const navigation = useNavigate();
  const [profileUri, setProfileUri] = useState(undefined);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    async function loadImg() {
      //Retreive the photo to gather and size based on
      //the source. Behavior after getting the image
      //from the source is the same.
      var profilePhoto;
      if (user?.profilePhoto && user?.profilePhoto.key) {
        profilePhoto = user.profilePhoto;
      } else if (
        notification?.notificationFromUserProfilePhoto &&
        notification?.notificationFromUserProfilePhoto.key
      ) {
        profilePhoto = notification.notificationFromUserProfilePhoto;
      }

      if (profilePhoto && profilePhoto.key) {
        const response = await Storage.get(profilePhoto.key.substring(7));
        setProfileUri(response);
      }
    }
    loadImg();
    return () => setIsMounted(false);
  }, [user, notification, isMounted]);
  if (overrideSize) {
    return (
      <Image
        size={"overrideSize"}
        src={profileUri !== undefined ? profileUri : "/bmv_icon.png"}
      />
    );
  }
  return (
    <Image
      src={profileUri !== undefined ? profileUri : "/bmv_icon.png"}
      size={"tiny"}
      avatar={square ? false : true}
      bordered
    />
  );
};
export default ProfilePhoto;
