import { useContext, useEffect, useState } from "react";
import { DataStore, Logger } from "aws-amplify";
import useUser from "./use-user";
import { SubscriptionPayment } from "../models";
import { registerUserWithRevenueCat } from "../constants";
import { differenceInDays } from "date-fns";
import { UserContext } from "../components/UserContext";

const logger = new Logger("UseIsSubscribed");
export default function useIsSubscribed() {
  const { user, isFetchingUser } = useContext(UserContext);
  const [isSubscribed, setIsSubscribed] = useState();
  useEffect(() => {
    const listener = (info) => {
      logger.info(
        "Info has been received in an update: " + info.entitlements.active
      );
      if (typeof info.entitlements.active.base_contractor !== "undefined") {
        //console.log("User is subscribed");
        setIsSubscribed(true);
        // Unlock that great "pro" content
      }
    };
    // Purchases.addPurchaserInfoUpdateListener(listener);

    // return () => {
    //   Purchases.removePurchaserInfoUpdateListener(listener);
    // };
  });
  useEffect(() => {
    if (user === undefined || isFetchingUser) {
      return;
    }
    (async () => {
      try {
        // const purchaserInfo = await Purchases.getPurchaserInfo();
        const payments = await DataStore.query(SubscriptionPayment, (sp) =>
          sp.userId.eq(user.id)
        );
        //console.log("is subscribed" + JSON.stringify(payments));
        const data = await registerUserWithRevenueCat(user);
        if (
          data.subscriber === undefined ||
          data.subscriber.entitlements.active === undefined
        ) {
        } else {
          setIsSubscribed(true);
          return;
        }
        const { subscriber } = data;

        if (
          typeof subscriber.entitlements?.active?.base_contractor !==
          "undefined"
        ) {
          //console.log(subscriber.entitlements.active);
          //user has access to some entitlement
          setIsSubscribed(true);
          return;
        } else if (
          payments.length > 0 &&
          payments.some((payment) => {
            return (
              differenceInDays(new Date(), new Date(payment.createdAt)) < 365
            );
          })
        ) {
          //console.log("Is subscribed mike!");
          setIsSubscribed(true);
          return;
        } else {
          const userGiftCardStatus = user?.subscriptionType;
          // //console.log("User: ", user);
          //console.log("UserGiftCardStatus: " + userGiftCardStatus);

          if (userGiftCardStatus !== undefined && userGiftCardStatus !== null) {
            setIsSubscribed(true);
            return;
          } else {
            setIsSubscribed(false);
          }
        }
      } catch (e) {
        //console.log(e);
      }
    })();
  }, [user, isFetchingUser]);
  return isSubscribed;
}
