import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ApplicationRow from "./components/ApplicationRow";
import { Container, Checkbox, FormControlLabel } from "@mui/material";
import { Label, Table, Menu, Transition } from "semantic-ui-react";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ApplicationsTab({ applications }) {
  const classes = useStyles();
  const [showOnlyInProgress, setShowOnlyInProgress] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const displayApplications = applications
    .filter((application) => {
      if (showOnlyInProgress) {
        return application.status === "InProgress";
      } else if (showApproved) {
        return application.status === "Approved";
      } else {
        return true;
      }
    })
    .sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  return (
    <Container>
      <FormControlLabel
        control={
          <Checkbox
            checked={showOnlyInProgress}
            onChange={(event) => {
              setShowApproved(false);
              setShowOnlyInProgress(event.target.checked);
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        }
        label={"Show only in progress"}
      ></FormControlLabel>
      <FormControlLabel
        control={
          <Checkbox
            checked={showApproved}
            onChange={(event) => {
              setShowOnlyInProgress(false);
              setShowApproved(event.target.checked);
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        }
        label={"Show only approved"}
      ></FormControlLabel>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Created at</Table.HeaderCell>
            <Table.HeaderCell>First name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone number</Table.HeaderCell>
            <Table.HeaderCell>Company name</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {displayApplications.map((application) => (
            <ApplicationRow key={application.id} application={application} />
          ))}
        </Table.Body>
      </Table>
      {/* <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Created at</TableCell>
              <TableCell align="right">First name</TableCell>
              <TableCell align="right">Last name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Phone number</TableCell>
              <TableCell align="right">Company name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayApplications.map((application) => (
              <ApplicationRow key={application.id} application={application} />
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </Container>
  );
}
