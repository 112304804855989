import { Auth, DataStore } from "aws-amplify";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import useAmbassador from "../hooks/use-ambassador";
import useIsSubscribed from "../hooks/use-is-subscribe";
import useSubscriptionPayment from "../hooks/use-subscription-payment";
import { UserContext } from "./UserContext";
import branch from "branch-sdk";
import MenuDrawer from "./HeaderMenuDrawer";
const HeaderMenu = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { user } = useContext(UserContext);
  const isSubscribed = useIsSubscribed();
  const ambassador = useAmbassador();
  const logout = async () => {
    branch.logout();
    DataStore.clear();
    await Auth.signOut();
    navigation("/login", { replace: true });
  };
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          borderBottomLeftRadius: 15,
          backgroundColor: "#000",
          borderBottomRightRadius: 15,
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              setIsMenuOpened(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: 'Montserrat', fontWeight: '800' }}>
            BidMyVid
          </Typography>

          {/* <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
            </Menu>
          </div> */}
        </Toolbar>
      </AppBar>
      <MenuDrawer
        isOpened={isMenuOpened}
        toggleClosed={() => {
          setIsMenuOpened(false);
        }}
      />
    </Box>
  );
};
export default HeaderMenu;
