import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { API, DataStore } from "aws-amplify";
import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { getEnvUrl } from "../constants";
import { User } from "../models";
import { redirect, useNavigate } from "react-router-dom";
import { Loader } from "semantic-ui-react";

export default function SetupPaymentsWidget({ user }: { user: User }) {
  const navigation = useNavigate();
  const [state, setState] = useImmer({
    isCreatingStripeAccount: false,
    isStripeAccountIdExists: false,
    isOnboardingAccount: false,
    isOnboarded: false,
    isInitializing: true,
  });
  useEffect(() => {
    if (user) {
      if (user.stripeAccountId) {
        const fetchAccountDetails = async () => {
          const getAccountDetailsResponse = await API.get(
            "api5ea1733e",
            `/stripe-connect/account/${user.stripeAccountId}`,
            { headers: {} }
          );
          console.log(JSON.stringify(getAccountDetailsResponse));
          if (getAccountDetailsResponse.charges_enabled) {
            console.log("Charges are enabled");
            setState((draft) => {
              draft.isCreatingStripeAccount = false;
              draft.isInitializing = false;
              draft.isOnboardingAccount = false;
              draft.isOnboarded = true;
              draft.isStripeAccountIdExists = true;
            });
          } else {
            setState((draft) => {
              draft.isCreatingStripeAccount = false;
              draft.isInitializing = false;
              draft.isOnboardingAccount = true;
              draft.isStripeAccountIdExists = true;
            });
          }
        };
        void fetchAccountDetails();
      } else {
        setState((draft) => {
          draft.isCreatingStripeAccount = false;
          draft.isInitializing = false;
          draft.isStripeAccountIdExists = false;
        });
      }
    }
  }, [state.isInitializing, user]);

  const linkToOnboard = async () => {
    const linkResponse = await API.post(
      "api5ea1733e",
      "/stripe-connect/account/link",
      {
        body: {
          account: user.stripeAccountId,
          return_url: getEnvUrl() + "/contractor",
          refresh_url: getEnvUrl() + "/contractor",
        },
        headers: {},
      }
    );
    // window.open(linkResponse.url);
    redirect(linkResponse.url);
  };
  const getStartedAcceptingPayments = async () => {
    setState((draft) => {
      draft.isCreatingStripeAccount = true;
    });
    const createResponse = await API.post(
      "api5ea1733e",
      "/stripe-connect/account/create",
      {
        body: { email: user.email },
        headers: {},
      }
    );
    //console.log(JSON.stringify(createResponse));
    await DataStore.save(
      User.copyOf(user, (updated) => {
        updated.stripeAccountId = createResponse.id;
      })
    );
    const linkResponse = await API.post(
      "api5ea1733e",
      "/stripe-connect/account/link",
      {
        body: {
          account: createResponse.id,
          return_url: getEnvUrl() + "/contractor",
          refresh_url: getEnvUrl() + "/contractor",
        },
        headers: {},
      }
    );
    window.location.replace(linkResponse.url);
  };

  if (state.isInitializing) {
    return <></>;
  }
  if (state.isOnboarded) {
    return (
      <div>
        <Card>
          <CardContent>
            <Typography variant={"h6"} sx={{ fontFamily: "Montserrat" }}>
              Payment Processing Complete
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => {
                window.location.replace("https://dashboard.stripe.com");
              }}
              sx={{ fontFamily: "Montserrat" }}
            >
              Manage Payment Profile
            </Button>
          </CardActions>
        </Card>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant={"h6"} sx={{ fontFamily: "Montserrat" }}>
              Download the app!
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => {
                window.location.replace(
                  "https://play.google.com/store/apps/details?id=bidmyvid.com.bmv&hl=en_US&gl=US&pli=1"
                );
              }}
              sx={{ fontFamily: "Montserrat" }}
            >
              Android
            </Button>
            <Button
              onClick={() => {
                window.location.replace(
                  "https://apps.apple.com/us/app/bidmyvid/id1276028261"
                );
              }}
              sx={{ fontFamily: "Montserrat" }}
            >
              iOS
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant={"h6"} sx={{ fontFamily: "Montserrat" }}>
          Complete payment processing setup
        </Typography>
      </CardContent>
      <CardActions>
        {!state.isStripeAccountIdExists && state.isCreatingStripeAccount && (
          <Loader inline active />
        )}
        {!state.isStripeAccountIdExists && (
          <Button
            disabled={state.isCreatingStripeAccount}
            onClick={getStartedAcceptingPayments}
            sx={{ fontFamily: "Montserrat" }}
          >
            Get started
          </Button>
        )}
        {state.isStripeAccountIdExists && state.isOnboardingAccount && (
          <Button
            onClick={() => {
              navigation("/payment-processing/redirect");
            }}
            sx={{ fontFamily: "Montserrat" }}
          >
            Finish setting up
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
