import React, { ReactElement, useState, useEffect } from "react";
import { Logger } from "@aws-amplify/core";
import { Storage } from "@aws-amplify/storage";
import { Job } from "../models";
const logger = new Logger("JobVideoView");

const JobVideo = ({ job, canPlay }) => {
  const [videoUri, setVideoUri] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  useEffect(() => {
    async function loadImg() {
      if (job?.video && job?.video.key) {
        try {
          const response = await Storage.get(
            job.video?.key.replace("public/", "")
          );
          if (typeof response === "string") {
            setVideoUri(response);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    loadImg();
    return () => setIsMounted(false);
  }, [job, isMounted]);
  return (
    <>
      {videoUri != "" && (
        <video
          height={window.screen.availHeight * 0.3}
          controls
          src={videoUri}
        />
      )}
    </>
  );
};

export default JobVideo;
