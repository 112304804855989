import React, { useState } from "react";
import { Auth, DataStore, Hub } from "aws-amplify";
import {
  TextField,
  Snackbar,
  Alert,
  Stack,
  Paper,
  FormGroup,
} from "@mui/material";
import {
  Input,
  Button,
  Divider,
  Message,
  Form,
  Header,
  Container,
} from "semantic-ui-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useLocation } from "react-router-dom";
import { capitalizeFirstLetter, pageStyle } from "../constants";
import { useEffect } from "react";
const ForgotPassword = ({ resetSuccess, goToLogin }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [sentCodeMessage, setSentCodeMessage] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const sendCode = async () => {
    if (email === "") {
      setErrorMessage("Email cannot be empty.");
    } else {
      // await Auth.fo
      try {
        const forgotPasswordResponse = await Auth.forgotPassword(
          capitalizeFirstLetter(email)
        );
        setIsCodeSent(true);
        //console.log(forgotPasswordResponse);
        setSentCodeMessage(
          "Reset code sent to " +
            forgotPasswordResponse.CodeDeliveryDetails.Destination
        );
        setEmail(capitalizeFirstLetter(email));
        return;
      } catch (e) {}
      try {
        const forgotPasswordResponse = await Auth.forgotPassword(
          email.toLowerCase()
        );
        setIsCodeSent(true);
        //console.log(forgotPasswordResponse);
        setSentCodeMessage(
          "Reset code sent to " +
            forgotPasswordResponse.CodeDeliveryDetails.Destination
        );
        setEmail(email.toLowerCase());
      } catch (e) {
        setErrorMessage(e.message);
        setShowErrorAlert(true);
      }
    }
  };
  const resetPassword = async () => {
    try {
      setIsResetting(true);
      const passwordResetResponse = await Auth.forgotPasswordSubmit(
        email,
        code,
        password
      );
      if (passwordResetResponse === "SUCCESS") {
        await login();
      }
    } catch (e) {
      setIsResetting(false);
      setErrorMessage(e.message);
      setShowErrorAlert(true);
    }
  };
  const login = async () => {
    setIsLoading(true);
    try {
      const response = await Auth.signIn({
        username: email,
        password: password,
      });

      setIsLoggedIn(true);
      DataStore.start();
      return;
    } catch (e) {}
    try {
      const response = await Auth.signIn({
        username: email.toLowerCase(),
        password: password,
      });

      setIsLoggedIn(true);
      DataStore.start();
    } catch (e) {
      //console.log(e.message);
      setIsLoading(false);
      setErrorMessage(e.message);
      setShowErrorAlert(true);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const listener = Hub.listen("datastore", (hubData) => {
        const { event, data } = hubData.payload;

        if (event == "ready") {
          setIsLoading(false);
          if (location.state.redirect) {
            navigation(location.state.redirect, { replace: true });
          } else {
            navigation("/", { replace: true });
          }

          resetSuccess();
        }
      });
      return () => {
        listener();
      };
    }
  }, [isLoggedIn, resetSuccess, navigation]);
  return (
    <Container style={pageStyle.mainContainer}>
      <Header as={"h1"}>Forgot Password</Header>
      {isCodeSent && (
        <Message>
          <Message.Content>{sentCodeMessage}</Message.Content>
        </Message>
      )}
      <Form error={showErrorAlert}>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <Form.Input
              error={showErrorAlert}
              label={"Email"}
              value={email}
              size={"large"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={"Email"}
            />
          </Form.Field>
        </Form.Group>
        {isCodeSent && (
          <Form.Group widths={"equal"}>
            <Form.Field>
              <Form.Input
                error={showErrorAlert}
                label={"Reset code"}
                value={code}
                size={"large"}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                placeholder={"Code ####"}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                sx={{ width: "25ch" }}
                label={"Password"}
                action={{
                  icon: showPassword ? "eye" : "eye slash",
                  onClick: () => {
                    setShowPassword((draft) => {
                      return !draft;
                    });
                  },
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                type={showPassword ? "text" : "password"}
              ></Form.Input>
            </Form.Field>
          </Form.Group>
        )}
        <Form.Group>
          {isCodeSent && (
            <Form.Button
              onClick={() => {
                void resetPassword();
              }}
              loading={isResetting}
              color={"red"}
            >
              Submit
            </Form.Button>
          )}
          <Form.Button
            onClick={() => {
              void sendCode();
            }}
            loading={isLoading}
          >
            Send code
          </Form.Button>
        </Form.Group>

        <Form.Button
          basic
          onClick={() => {
            // goToSignUp();
            goToLogin();
          }}
        >
          Have an account? Login here
        </Form.Button>

        <Message
          onDismiss={() => {
            setShowErrorAlert(false);
          }}
          error
        >
          <Message.Header>Failed</Message.Header>
          <Message.Content>{errorMessage}</Message.Content>
        </Message>
      </Form>

      {/* <Snackbar
        open={showErrorAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => {
          setShowErrorAlert(false);
        }}
      >
        <Alert variant={"filled"} severity={"error"}>
          {errorMessage}
        </Alert>
      </Snackbar> */}
    </Container>
  );
};

export default ForgotPassword;
