import React, { useState, useEffect } from "react";
import {
  Stack,
  Checkbox,
  Box,
  FormControlLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Form,
  Header,
  Container,
  Button,
  Message,
  Grid,
} from "semantic-ui-react";
import MFAForm from "./MfaForm";
import { User } from "../models/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useImmer } from "use-immer";
import { Colors, pageStyle, registerUserWithRevenueCat } from "../constants";
import { Auth, DataStore } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { Typography } from "@mui/material";
const SignupForm = ({ goToLogin }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [signupData, setSignupData] = useImmer({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    showPassword: false,
    showErrorAlert: false,
    errorMessage: "",
    isCreatingAccount: false,
    isMfa: false,
    isSubmittingMfa: false,
    mfaErrorMessage: "",
    mfaCode: "",
    codeSentTo: "",
  });

  const toggleIsCreatingAccount = () => {
    setSignupData((draft) => {
      draft.isCreatingAccount = !draft.isCreatingAccount;
    });
  };
  const toggleIsSubmittingMfaCode = () => {
    setSignupData((draft) => {
      draft.isSubmittingMfa = !draft.isSubmittingMfa;
    });
  };
  const createAccount = async () => {
    try {
      toggleIsCreatingAccount();
      const signUpResponse = await Auth.signUp({
        username: signupData.email.toLowerCase(),
        password: signupData.password,
        attributes: {
          email: signupData.email.toLowerCase(),
          phone_number: "+1" + signupData.phoneNumber,
          "custom:firstName": signupData.firstName,
          "custom:lastName": signupData.lastName,
        },
      });

      //console.log(signUpResponse);
      setSignupData((draft) => {
        draft.isCreatingAccount = false;
        draft.isMfa = true;
        draft.codeSentTo = _.get(
          signUpResponse,
          "codeDeliveryDetails.Destination",
          ""
        );
        //console.log("code sent to: " + draft.codeSentTo);
      });
    } catch (e) {
      setSignupData((draft) => {
        draft.showErrorAlert = true;
        draft.errorMessage = e.message;
      });
    }
    toggleIsCreatingAccount();
  };
  const confirmAccount = async () => {
    try {
      toggleIsSubmittingMfaCode();
      const username = signupData.email.toLowerCase();
      const signUpResponse = await Auth.confirmSignUp(
        username,
        signupData.mfaCode
      );
      await Auth.signIn(username, signupData.password);
      const user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      const firstName = attributes["custom:firstName"];
      const lastName = attributes["custom:lastName"];
      const phoneNumber = attributes["phone_number"];
      const email = attributes["email"];
      //console.log(JSON.stringify(attributes));
      const userResponse = await DataStore.save(
        new User({
          firstName: signupData.firstName,
          lastName: signupData.lastName,
          phoneNumber: "+1" + signupData.phoneNumber,
          email: signupData.email,
          cognitoId: user.username,
        })
      );
      await registerUserWithRevenueCat(userResponse.id);
      //console.log(signUpResponse);
      if (location.state.redirect) {
        navigation(location.state.redirect, { replace: true });
      } else {
        navigation("/");
      }
    } catch (e) {
      setSignupData((draft) => {
        draft.showErrorAlert = true;
        draft.errorMessage = e.message;
      });
    }
    toggleIsSubmittingMfaCode();
  };
  if (signupData.isMfa) {
    return (
      <>
        <MFAForm
          state={signupData}
          updateState={setSignupData}
          confirmAccount={confirmAccount}
        />
        {signupData.showErrorAlert && (
          <Message error>
            <Message.Header>{signupData.errorMessage}</Message.Header>
          </Message>
        )}
        {/* <Snackbar
          open={signupData.showErrorAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => {
            setSignupData((draft) => {
              draft.showErrorAlert = false;
            });
          }}
        >
          <Alert variant={"filled"} severity={"error"}>
            {signupData.errorMessage}
          </Alert>
        </Snackbar> */}
      </>
    );
  }
  return (
    <Container style={pageStyle.mainContainer}>
      <Typography fontFamily={"Montserrat"}>
        <Message info>
          <Message.Header>
            Create an account to take advantage of the lifetime offer!
          </Message.Header>
        </Message>
        <Form
          error={signupData.showErrorAlert}
          onSubmit={() => {
            void createAccount();
          }}
        >
          <Header as={"h1"}>Create Account</Header>
          <Message error>
            <Message.Header>Failed to create account</Message.Header>
            <Message.Content>{signupData.errorMessage}</Message.Content>
          </Message>
          <Form.Input
            label={"First name"}
            onChange={(e) => {
              setSignupData((draft) => {
                draft.firstName = e.target.value;
              });
            }}
            value={signupData.firstName}
          ></Form.Input>
          <Form.Input
            label={"Last name"}
            onChange={(e) => {
              setSignupData((draft) => {
                draft.lastName = e.target.value;
              });
            }}
            value={signupData.lastName}
          ></Form.Input>
          <Form.Input
            label={"Email"}
            onChange={(e) => {
              setSignupData((draft) => {
                draft.email = e.target.value;
              });
            }}
            value={signupData.email}
            type={"email"}
          ></Form.Input>

          <Form.Input
            sx={{ width: "25ch" }}
            label={"Password"}
            action={{
              icon: signupData.showPassword ? "eye" : "eye slash",
              onClick: () => {
                setSignupData((draft) => {
                  draft.showPassword = !draft.showPassword;
                });
              },
            }}
            // InputProps={{
            //   endAdornment: (
            //     <div>
            //       {signupData.showPassword && (
            //         <VisibilityIcon
            //           sx={{ color: Colors.LIGHT_GRAY }}
            //           onClick={() => {
            //             setSignupData((draft) => {
            //               draft.showPassword = !draft.showPassword;
            //             });
            //           }}
            //         />
            //       )}
            //       {!signupData.showPassword && (
            //         <VisibilityOffIcon
            //           sx={{ color: Colors.LIGHT_GRAY }}
            //           onClick={() => {
            //             setSignupData((draft) => {
            //               draft.showPassword = !draft.showPassword;
            //             });
            //           }}
            //         />
            //       )}
            //     </div>
            //   ),
            // }}
            onChange={(e) => {
              setSignupData((draft) => {
                draft.password = e.target.value;
              });
            }}
            value={signupData.password}
            type={signupData.showPassword ? "text" : "password"}
          ></Form.Input>

          <Form.Input
            label={"Phone number"}
            onChange={(e) => {
              setSignupData((draft) => {
                let value = e.target.value.replace(/[a-zA-Z-]/gi, "");
                draft.phoneNumber = value;
              });
            }}
            value={signupData.phoneNumber}
            type={"tel"}
          ></Form.Input>
          <Form.Button loading={signupData.isCreatingAccount} color={"red"}>
            Submit
          </Form.Button>
          <Form.Button onClick={goToLogin}>Have an account? Login</Form.Button>

          {/* <Snackbar
        open={signupData.showErrorAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => {
          setSignupData((draft) => {
            draft.showErrorAlert = false;
          });
        }}
      >
        <Alert variant={"filled"} severity={"error"}>
          {signupData.errorMessage}
        </Alert>
      </Snackbar> */}
        </Form>
      </Typography>
    </Container>
  );
};

export default SignupForm;
