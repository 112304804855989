import { useState, useEffect } from "react";
import { DataStore, Logger } from "aws-amplify";
import { Bid, Message } from "../models";
import { OpType } from "@aws-amplify/datastore";
const logger = new Logger("useJobBids");
export default function useJobBids(jobId) {
  const [bids, setBids] = useState([]);
  const [initialFetch, setInitialFetch] = useState(true);
  const [uniqueJobBidListId, setUniqueJobBidListId] =
    useState("unique-job-list");
  useEffect(() => {
    if (jobId && initialFetch) {
      (async () => {
        try {
          const bids = await DataStore.query(Bid, (b) =>
            b.bidJobId.eq(jobId).denied("ne", true)
          );
          setBids(bids);
          if (bids.length > 0) {
            setUniqueJobBidListId(bids[0].updatedAt);
          }
          setInitialFetch(false);
        } catch (e) {
          logger.warn("Failed to fetch bids for job" + jobId, e);
        }
      })();
    }
  }, [jobId, initialFetch]);
  useEffect(() => {
    const subscription = DataStore.observe(Bid, (b) =>
      b.bidJobId.eq(jobId)
    ).subscribe((msg) => {
      //   console.log(msg.model, msg.opType, msg.element);
      if (msg.opType === OpType.INSERT) {
        setBids((bids) =>
          [...bids, msg.element].sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          )
        );
        setUniqueJobBidListId(msg.element.updatedAt);
      }
      if (msg.opType === OpType.UPDATE) {
        console.log(msg.model, msg.opType, msg.element);
        setBids((bids) => [
          ...bids
            .map((existingBid) => {
              if (existingBid.id === msg.element.id) {
                console.log(
                  "Found existing bid to replace " + JSON.stringify(existingBid)
                );
                console.log("With new " + JSON.stringify(msg.element));
              }

              return existingBid.id !== msg.element.id
                ? existingBid
                : msg.element;
            })
            .filter((bid) => bid.denied !== true)
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            ),
        ]);
        setUniqueJobBidListId(msg.element.updatedAt);
      }
      if (msg.opType === OpType.DELETE) {
        setBids((bids) => [
          ...bids
            .filter((b) => b.id !== msg.element.id)
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            ),
        ]);
        setUniqueJobBidListId(msg.element.updatedAt);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return {
    bids,
    uniqueJobBidListId,
    isFetchingBids: initialFetch,
  };
}
