import React from "react";
import {
  Typography,
  CardActions,
  Chip,
  Card,
  CardContent,
} from "@mui/material";

export default function WelcomeClientWidget() {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" sx={{ fontFamily: "Montserrat", flexGrow: 1 }}>
          Need work done as a client?
        </Typography>
      </CardContent>

      <CardActions>
        <Chip
          sx={{ fontFamily: "Montserrat" }}
          label="Download app"
          component="a"
          href={"https://bidmyvid.app.link/jzt3fPMkqub"}
          variant="outlined"
          clickable
        />
      </CardActions>
    </Card>
  );
}
