import { API } from "aws-amplify";
import React, { useContext, useEffect } from "react";
import { Navigate, redirect } from "react-router-dom";
import { useImmer } from "use-immer";
import Page from "../components/Page";
import { UserContext } from "../components/UserContext";
import { getEnvUrl } from "../constants";
import { User } from "../models";
const PaymentProcessingRedirect = () => {
  const [state, setState] = useImmer<{ redirectUrl?: string }>({
    redirectUrl: undefined,
  });
  const { user, isFetchingUser } = useContext<{
    user: User | undefined;
    isFetchingUser: boolean;
  }>(UserContext);
  useEffect(() => {
    if (user) {
      if (user.stripeAccountId) {
        const linkToOnboard = async () => {
          const linkResponse = await API.post(
            "api5ea1733e",
            "/stripe-connect/account/link",
            {
              body: {
                account: user.stripeAccountId,
                return_url: getEnvUrl() + "/contractor",
                refresh_url: getEnvUrl() + "/payment-processing/redirect",
              },
              headers: {},
            }
          );
          // window.open(linkResponse.url);
          setState((draft) => {
            draft.redirectUrl = linkResponse.url;
          });
        };
        const fetchAccountDetails = async () => {
          const getAccountDetailsResponse = await API.get(
            "api5ea1733e",
            `/stripe-connect/account/${user.stripeAccountId}`,
            { headers: {} }
          );
          console.log(JSON.stringify(getAccountDetailsResponse));
          if (getAccountDetailsResponse.charges_enabled) {
            setState((draft) => {
              draft.redirectUrl = "https://dashboard.stripe.com";
            });
          } else {
            void linkToOnboard();
          }
        };
        void fetchAccountDetails();
      } else {
      }
    }
  }, [user]);

  if (state.redirectUrl) {
    window.location.replace(state.redirectUrl);
  }

  return <Page></Page>;
};

export default PaymentProcessingRedirect;
