import React from "react";
import {
  Typography,
  CardActions,
  Chip,
  Card,
  CardContent,
} from "@mui/material";

export default function InterestedInBecomingServiceProviderWidget() {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" sx={{ fontFamily: "Montserrat", flexGrow: 1 }}>
          Interested in being a service provider?
        </Typography>
      </CardContent>

      <CardActions>
        <Chip
          sx={{ fontFamily: "Montserrat" }}
          label="Click here"
          component="a"
          href="/application/pay"
          variant="outlined"
          clickable
        />
      </CardActions>
    </Card>
  );
}
