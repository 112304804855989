import React, { useContext, useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import Page from "../components/Page";
import InterestedInBecomingServiceProviderWidget from "../components/InterestedInBecomingServiceProviderWidget";
import useIsSubscribed from "../hooks/use-is-subscribe";
import { Box, Typography } from "@mui/material";
import WelcomeClientWidget from "../components/WelcomeClientWidget";
import SimpleWidget from "../components/SimpleWidget";
import useAmbassador from "../hooks/use-ambassador";
const HomePage = () => {
  const navigation = useNavigate();
  const { user } = useContext(UserContext);
  const isSubscribed = useIsSubscribed();
  const { data: ambassadorData, isFetching: isFetchingAmbassador } =
    useAmbassador();
  const [isDataFetched, setIsDataFetched] = useState(false);
  useEffect(() => {
    //console.log("home user check with context" + user);
    if (user) {
      setIsDataFetched(true);
    }
  }, [user]);
  if (!isDataFetched) {
    return <Loader inline active />;
  }
  return (
    <Page>
      <WelcomeClientWidget />
      {isSubscribed === false && (
        <Box sx={{ marginY: 2 }}>
          <InterestedInBecomingServiceProviderWidget />
        </Box>
      )}
      <Box sx={{ marginY: 2 }}>
        <SimpleWidget title={"Quick links"} actions={[]}>
          <Box sx={{ marginY: 2 }}>
            {isSubscribed && (
              <a href={"/contractor"}>
                <Typography fontFamily={"Montserrat"}>
                  Contractor dashboard
                </Typography>
              </a>
            )}
            {!isFetchingAmbassador &&
              (!ambassadorData || !ambassadorData.approvedAt) && (
                <a href={"/ambassador/signup"}>
                  <Typography fontFamily={"Montserrat"}>
                    Become an ambassador!
                  </Typography>
                </a>
              )}
            {ambassadorData && ambassadorData.isApproved && (
              <a href={"/ambassador"}>
                <Typography fontFamily={"Montserrat"}>
                  Ambassador dashboard
                </Typography>
              </a>
            )}
          </Box>
        </SimpleWidget>
      </Box>
    </Page>
  );
};

export default HomePage;
