import Axios from "axios";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../components/UserContext";
import branch from "branch-sdk";
import { API, DataStore } from "aws-amplify";
import { displayUserFullName, getEnvUrl } from "../constants";
import { useImmer } from "use-immer";
import Page from "../components/Page";
import {
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  CardActions,
} from "@mui/material";
import { User } from "../models";
import SetupPaymentsWidget from "../components/SetupPaymentsWidget";

const ContractorTab = () => {
  const { user, isFetchingUser } = useContext<{
    user: User | undefined;
    isFetchingUser: boolean;
  }>(UserContext);

  return (
    <Page>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          {user && <SetupPaymentsWidget user={user} />}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant={"h6"} sx={{ fontFamily: "Montserrat" }}>
                Onboarding videos
              </Typography>
              <Typography variant={"body1"} sx={{ fontFamily: "Montserrat" }}>
                <a href={"https://app.bidmyvid.com/onboarding"}>
                  Step through onboarding!
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* <Button
        onClick={() => {
          branch.link(
            {
              feature: "profile link share",
              channel: "web",
              data: {
                $og_description: "Need work done? Send a video directly to me.",
                $og_image_url: null,
                $og_title: `${displayUserFullName(user)} Profile`,
                $ios_deeplink_path: `bidmyvid://service/profile/${user?.id}`,
                $ios_url: `bidmyvid://service/profile/${user?.id}`,
                // $referenceUrl_android: `bidmyvid://service/profile/${selectedUser.id}`,
                $desktop_url: `${getEnvUrl()}/profile/${user?.id}`,
                $canonical_url: getEnvUrl(),
              },
            },
            async (err, link) => {
              if (link) {
                await navigator.clipboard.writeText(link);
              }
            }
          );
        }}
      >
        Copy Profile Link
      </Button> */}
    </Page>
  );
};

export default ContractorTab;
