import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Auth, DataStore } from "aws-amplify";
import {
  AppBar,
  Tabs,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Applications from "./ApplicationsTab";
import SummaryTab from "./SummaryTab";
import UserTab from "./UsersTab";
import JobsTab from "./JobsTab";
import { ServiceProviderApplication, User, Job } from "./models";
import {
  Tab,
  Dimmer,
  Loader,
  Container,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";
import ManageAmbassadors from "./pages/ManageAmbassadors";
export default function Dashboard() {
  const [value, setValue] = React.useState(0);
  const [applications, setApplications] = useState([]);
  const [users, setUsers] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [isFetchingJobs, setIsFetchingJobs] = useState(true);
  const [isFetchingApplications, setIsFetchingApplications] = useState(true);
  const [isDataFetched, setIsDataFetched] = useState(false);
  useEffect(() => {
    if (!isFetchingApplications && !isFetchingJobs && !isFetchingUsers) {
      setIsDataFetched(true);
    }
  }, [isFetchingApplications, isFetchingJobs, isFetchingUsers]);

  useEffect(() => {
    const fetchApplications = async () => {
      setIsFetchingApplications(true);

      const items = await DataStore.query(ServiceProviderApplication);
      setIsFetchingApplications(false);
      setApplications(items);
    };
    const fetchUsers = async () => {
      setIsFetchingUsers(true);
      const items = await DataStore.query(User);
      setIsFetchingUsers(false);
      setUsers(items);
    };
    const fetchJobs = async () => {
      setIsFetchingJobs(true);
      const items = await DataStore.query(Job);
      setIsFetchingJobs(false);
      setJobs(items);
    };
    const fetchDashboard = async () => {
      const currentUser = await Auth.currentUserInfo();
      if (currentUser.attributes.email.includes("bidmyvid.com")) {
        fetchUsers();
        fetchApplications();
        fetchJobs();
      } else {
        await Auth.signOut();
        window.location.reload();
      }
    };
    if (isFetchingApplications && isFetchingJobs && isFetchingUsers) {
      fetchDashboard();
    }
  }, [isFetchingApplications, isFetchingJobs, isFetchingUsers]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  if (!isDataFetched) {
    return <Loader inline active />;
  }
  {
    /* <AppBar position="static">
        <Tabs
          variant={"fullWidth"}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Summary" />
          <Tab label="Applications" />
          <Tab label="Users" />
          <Tab label="Jobs" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <SummaryTab applications={applications} users={users} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Applications applications={applications} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UserTab users={users} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <JobsTab
          jobs={jobs
            .filter((job) => !job.clientCompletedAt)
            .sort((a, b) => {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.createdAt) - new Date(a.createdAt);
            })}
        />
      </TabPanel> */
  }
  return (
    <Container>
      <Segment basic floated="right">
        <Button
          icon
          onClick={() => {
            setIsFetchingApplications(true);
            setIsFetchingJobs(true);
            setIsFetchingUsers(true);
          }}
        >
          <Icon name={"refresh"} />
        </Button>
      </Segment>
      <Tab
        panes={[
          {
            menuItem: "Summary",
            render: () => (
              <SummaryTab applications={applications} users={users} />
            ),
          },
          {
            menuItem: "Applications",
            render: () => <Applications applications={applications} />,
          },
          {
            menuItem: "Users",
            render: () => <UserTab users={users} />,
          },
          {
            menuItem: "Ambassadors",
            render: () => <ManageAmbassadors />,
          },
          {
            menuItem: "Jobs",
            render: () => (
              <JobsTab
                jobs={jobs
                  .filter((job) => !job.clientCompletedAt)
                  .sort((a, b) => {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  })}
              />
            ),
          },
        ]}
      />
    </Container>
  );
}
