import useManageAmbassadors from "../hooks/use-manage-ambassadors";
import { Button, Container, Checkbox, Icon, Table } from "semantic-ui-react";
import AmbasssadorTableRow from "../components/AmbassadorTableRow";

const ManageAmbassadors = () => {
  const {
    ambassadors,
    isApproving,
    isDenying,
    isFetchingAmbassadors,
    approveAmbassador,
    denyAmbassador,
  } = useManageAmbassadors();
  console.log("Number of ambassadors = " + ambassadors.length);
  return (
    <Table compact celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>OAP</Table.HeaderCell>
          <Table.HeaderCell>W9</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {ambassadors.map((ambassador) => {
          return <AmbasssadorTableRow ambassador={ambassador} />;
        })}
      </Table.Body>
    </Table>
  );
};
export default ManageAmbassadors;
