import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import useIsSubscribed from "../hooks/use-is-subscribe";
import useAmbassador from "../hooks/use-ambassador";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useImmer } from "use-immer";
import { Auth, DataStore } from "aws-amplify";
import branch from "branch-sdk";
import { getEnvUrl } from "../constants";
import { UserContext } from "./UserContext";
import { User } from "../models";

type Anchor = "top" | "left" | "bottom" | "right";

export default function MenuDrawer({
  isOpened,
  toggleClosed,
}: {
  isOpened: boolean;
  toggleClosed: () => void;
}) {
  const navigation = useNavigate();
  const [state, setState] = useImmer({
    isLoggedIn: false,
  });
  const { user } = useContext<{ user?: User; isFetchingUser: boolean }>(
    UserContext
  );
  const isSubscribed = useIsSubscribed();
  const { data, isFetching } = useAmbassador();

  const drawerMenuListItems = useMemo(
    () => [
      { text: "Home", path: "/", icon: () => <HomeIcon />, isLoggedIn: true },
      {
        text: "Become a contractor",
        badgeText: "Lifetime access!",
        path: "/application/pay",
        icon: () => <HomeRepairServiceIcon />,
        isLoggedIn: true,
        isSubscribed: false,
      },
      {
        text: "Contractor Dashboard",
        path: "/contractor",
        icon: () => <HomeRepairServiceIcon />,
        isLoggedIn: true,
        isSubscribed: true,
      },
      {
        text: "Become an ambassador",
        path: "/ambassador/signup",
        icon: () => <AttachMoneyIcon />,
        isLoggedIn: true,
        isAmbassador: false,
      },
      {
        text: "My Ambassador Dashboard",
        path: "/ambassador",
        icon: () => <AttachMoneyIcon />,
        isLoggedIn: true,
        isAmbassador: true,
      },
      {
        text: "Admin Dashboard",
        path: "/dashboard",
        icon: () => <AttachMoneyIcon />,
        isLoggedIn: true,
        isEmployee: true,
      },
    ],
    []
  );
  useEffect(() => {
    (async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setState((draft) => {
          draft.isLoggedIn = true;
        });
      } catch (e) {}
    })();
  }, [user]);
  const logout = async () => {
    branch.logout();
    await DataStore.clear();
    await Auth.signOut();
    navigation("/login", { replace: true });
  };
  const getDrawerItemsToDisplay = React.useCallback(() => {
    let items = drawerMenuListItems;
    // filter drawer items whether the user is logged in
    if (state.isLoggedIn) {
      items = items.filter((item) => item.isLoggedIn === true);
    } else {
      items = items.filter((item) => item.isLoggedIn === false);
    }
    if (user && user.email?.includes("@bidmyvid.com")) {
    } else {
      items = items.filter(
        (item) => item.isEmployee === false || item.isEmployee === undefined
      );
    }
    // Filter drawer items for whether they are subscribed
    if (isSubscribed) {
      items = items.filter(
        (item) => item.isSubscribed === true || item.isSubscribed === undefined
      );
    } else {
      items = items.filter(
        (item) => item.isSubscribed === false || item.isSubscribed === undefined
      );
    }
    // Filter drawer items for ambassador data
    if (data && data.isApproved) {
      items = items.filter(
        (item) => item.isAmbassador === true || item.isAmbassador === undefined
      );
    } else {
      items = items.filter(
        (item) => item.isAmbassador === false || item.isAmbassador === undefined
      );
    }
    return items;
  }, [isSubscribed, drawerMenuListItems, state.isLoggedIn, data, user]);
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleClosed}
      onKeyDown={toggleClosed}
    >
      <List>
        {getDrawerItemsToDisplay().map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              onClick={() => {
                navigation(item.path);
              }}
            >
              <ListItemIcon>{item.icon()}</ListItemIcon>
              <ListItemText primary={item.text} secondary={item.badgeText} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {state.isLoggedIn && (
          <ListItem key={"logout"} disablePadding>
            <ListItemButton
              onClick={() => {
                void logout();
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer anchor={"left"} open={isOpened} onClose={toggleClosed}>
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
