// @ts-nocheck
import { DataStore, Storage } from "aws-amplify";
import { useEffect } from "react";
import React, { useContext, useState } from "react";
import {
  Container,
  Divider,
  Input,
  Loader,
  Message,
  Progress,
  Step,
} from "semantic-ui-react";
import { UserContext } from "../components/UserContext";
import { pageStyle } from "../constants";
import useAmbassador from "../hooks/use-ambassador";
import { Ambassador, User } from "../models";
import { useImmer } from "use-immer";
import AmbassadorSubmissionStatus from "../components/AmbassadorSubmissionStatus";
import "./AmbassadorSignUp.css";
import Page from "../components/Page";
import branch from "branch-sdk";
import {
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
const AmbassadorSignUpStep = {
  Intro: 0,
  OAP: 1,
  W9: 2,
  SUBMIT: 3,
  PENDING: 4,
};
const AmbassadorSignUpPage = () => {
  const { user } = useContext<{ user?: User; isFetchingUser: boolean }>(
    UserContext
  );
  const { data, isFetching } = useAmbassador();
  const [signupState, setSignupState] = useImmer<{
    currentStep: number;
    isSubmitting: boolean;
    isAcceptedTC: boolean;
    isInitializing: boolean;
    completedSteps: number[];
    ambLink: string;
    clickedTCLink: boolean;
    oapAttachment: File | undefined;
    errorUploadingOAP: string;
    w9Attachment: File | undefined;
    errorUploadingW9: string;
    isW9Uploaded: boolean;
    isUploading: boolean;
    uploadProgress: number | undefined;
  }>({
    currentStep: AmbassadorSignUpStep.W9,
    isSubmitting: false,
    isAcceptedTC: false,
    isInitializing: true,
    completedSteps: [],
    ambLink: "",
    clickedTCLink: false,
    oapAttachment: undefined,
    errorUploadingOAP: "",
    w9Attachment: undefined,
    errorUploadingW9: "",
    isW9Uploaded: false,
    isUploading: false,
    uploadProgress: undefined,
  });
  useEffect(() => {
    if (!isFetching && signupState.isInitializing) {
      if (data) {
        branch.link(
          {
            feature: "ambassador link",
            channel: "web",
            data: {
              $fallback_url: `https://app.bidmyvid.com/application/pay?ambRefId=${data.id}`,
              $og_description: "Get lifetime access to platinum membership!",
              $og_image_url:
                "https://content.bidmyvid.com/logos/bmv-platinum.jpg",
              $og_title: `BidMyVid Platinum`,
              $ios_url: `https://app.bidmyvid.com/application/pay?ambRefId=${data.id}`,
              // $referenceUrl_android: `bidmyvid://service/profile/${selectedUser.id}`,
              $desktop_url: `https://app.bidmyvid.com/application/pay?ambRefId=${data.id}`,
              $mobile_web_only: true,
              $web_only: true,
              $canonical_url: `https://app.bidmyvid.com`,
            },
          },
          async (err: { message: string }, link: string) => {
            if (link) {
              setSignupState((draft) => {
                draft.ambLink = link;
              });
            }
          }
        );
        let lastStepCompleted = AmbassadorSignUpStep.OAP;
        //console.log("initialize " + JSON.stringify(data));
        if (data.isSubmitted) {
          lastStepCompleted = AmbassadorSignUpStep.PENDING;
          setSignupState((draft) => {
            draft.completedSteps.push(AmbassadorSignUpStep.SUBMIT);
          });
        }
        if (data.oapAccepted) {
          lastStepCompleted = AmbassadorSignUpStep.W9;
          setSignupState((draft) => {
            draft.completedSteps.push(AmbassadorSignUpStep.OAP);
          });
        }
        if (data.w9) {
          lastStepCompleted = AmbassadorSignUpStep.SUBMIT;
          setSignupState((draft) => {
            draft.completedSteps.push(AmbassadorSignUpStep.W9);
          });
        }
        setSignupState((draft) => {
          draft.currentStep = lastStepCompleted;
          draft.isInitializing = false;
          //console.log(draft.completedSteps.toString());
        });
      } else {
        setSignupState((draft) => {
          draft.currentStep = AmbassadorSignUpStep.Intro;
          draft.isInitializing = false;
        });
      }
    }
  }, [isFetching, data, signupState.isInitializing]);
  const startSignUp = async () => {
    if (user) {
      // @ts-ignore
      await DataStore.save(
        // @ts-ignore
        new Ambassador({
          userId: user.id,
        })
      );
      setSignupState((draft) => {
        draft.currentStep = AmbassadorSignUpStep.OAP;
      });
    }
  };

  const saveW9 = async (key: string) => {
    if (data) {
      await DataStore.save(
        Ambassador.copyOf(data, (update) => {
          update.w9 = {
            bucket: "test",
            key,
            region: "NA",
          };
        })
      );
      setSignupState((draft) => {
        draft.isW9Uploaded = true;
      });
    }
  };

  const uploadW9 = async () => {
    try {
      setSignupState((draft) => {
        draft.isUploading = true;
      });
      const key = `${user?.id}/ambassador/${signupState.w9Attachment?.name}`;
      const upload = await Storage.put(key, signupState.w9Attachment, {
        resumable: true,
        completeCallback: (event) => {
          setSignupState((draft) => {
            draft.isUploading = false;
            draft.w9Attachment = undefined;
            draft.uploadProgress = undefined;
          });
          saveW9(key);
          //console.log(`Successfully uploaded ${JSON.stringify(event)}`);
        },
        progressCallback: (progress) => {
          //console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          setSignupState((draft) => {
            draft.uploadProgress = progress.loaded / progress.total;
          });
        },
        errorCallback: (err) => {
          setSignupState((draft) => {
            draft.errorUploadingW9 = err;
            draft.isUploading = false;
          });
          console.error("Unexpected error while uploading", err);
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const acceptTerms = async () => {
    if (data) {
      await DataStore.save(
        Ambassador.copyOf(data, (updated) => {
          updated.oapAcceptedAt = new Date().toISOString();
          updated.oapAccepted = true;
        })
      );
    }

    setSignupState((draft) => {
      draft.completedSteps.push(AmbassadorSignUpStep.OAP);
      draft.currentStep = AmbassadorSignUpStep.W9;
    });
  };

  if (isFetching) {
    return <Loader active />;
  }
  if (data) {
    if (data.isSubmitted) {
      return <AmbassadorSubmissionStatus data={data} />;
    }
    return (
      <Page>
        <Step.Group>
          <Step
            onClick={() => {
              setSignupState((draft) => {
                draft.currentStep = AmbassadorSignUpStep.OAP;
              });
            }}
            active={signupState.currentStep === AmbassadorSignUpStep.OAP}
          >
            <Step.Content>
              <Step.Title>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  Terms and conditions
                </Typography>
              </Step.Title>
            </Step.Content>
          </Step>

          <Step
            disabled={
              !signupState.completedSteps.includes(AmbassadorSignUpStep.OAP)
            }
            onClick={() => {
              setSignupState((draft) => {
                draft.currentStep = AmbassadorSignUpStep.W9;
              });
            }}
            active={signupState.currentStep === AmbassadorSignUpStep.W9}
          >
            <Step.Content>
              <Step.Title>
                <Typography sx={{ fontFamily: "Montserrat" }}>W-9</Typography>
              </Step.Title>
            </Step.Content>
          </Step>

          <Step
            onClick={() => {
              setSignupState((draft) => {
                draft.currentStep = AmbassadorSignUpStep.SUBMIT;
              });
            }}
            active={signupState.currentStep === AmbassadorSignUpStep.SUBMIT}
            disabled={
              !signupState.completedSteps.includes(AmbassadorSignUpStep.W9)
            }
          >
            <Step.Content>
              <Step.Title>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  Your link
                </Typography>
              </Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
        {signupState.currentStep === AmbassadorSignUpStep.OAP && (
          <div>
            {data.OAP ? (
              <Message success>
                <Message.Header>Uploaded!</Message.Header>
                <Message.Content>Click next to continue</Message.Content>
              </Message>
            ) : (
              <></>
            )}
            <Box sx={{ flexGrow: 0.5 }}>
              <Typography sx={{ fontFamily: "Montserrat" }}>
                <b>
                  AUTOMATICALLY gain EARNINGS* of $200.00 for each Platinum
                  Lifetime Subscription
                </b>{" "}
                via your affiliate link as our BidMyVid® Opportunity Ambassador
                Affiliate Program member! Earnings payout are issued each fiscal
                Quarter.
              </Typography>
              <Box sx={{ mt: 1, mb: 1 }}>
                <ul>
                  <Typography sx={{ fontFamily: "Montserrat" }}>
                    QTR 1 (Jan-Mar) Statement and payouts issued by April 20th
                  </Typography>
                  <Typography sx={{ fontFamily: "Montserrat" }}>
                    QTR 2 (Apr-Jun) Statement and payouts issued by July 20th
                  </Typography>
                  <Typography sx={{ fontFamily: "Montserrat" }}>
                    QTR 3 (Jul-Sep) Statement and payouts issued by October 20th
                  </Typography>
                  <Typography sx={{ fontFamily: "Montserrat" }}>
                    QTR 4 (Oct-Dec) Statement and payouts issued by January 20th
                  </Typography>
                </ul>
              </Box>
              <Typography
                sx={{ fontFamily: "Montserrat" }}
                variant={"subtitle2"}
              >
                *Earnings come from Application Conversions that have paid the
                $499.25 fee in its entirety less any refund or charge-backs
                within that Quarter and/or previous Quarters.
              </Typography>
              <Typography sx={{ fontFamily: "Montserrat", paddingTop: 2 }}>
                <b>In order to agree, </b>
                <Typography sx={{ fontFamily: "Montserrat", marginTop: 2 }}>
                  <a
                    onClick={() => {
                      setSignupState((draft) => {
                        draft.clickedTCLink = true;
                      });
                    }}
                    href={
                      "https://content.bidmyvid.com/affiliates/PlatinumOpportunityAmbassadorProgram-TermsandConditions-Rev22.1.pdf"
                    }
                    rel={"noreferrer"}
                    target={"_blank"}
                  >
                    View Terms and Conditions
                  </a>
                </Typography>
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Tooltip
                  title={
                    <Typography sx={{ fontFamily: "Montserrat" }}>
                      Please click the 'View Terms and Conditions' first
                    </Typography>
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setSignupState((draft) => {
                            draft.isAcceptedTC = e.target.checked;
                          });
                        }}
                        disabled={!signupState.clickedTCLink}
                      />
                    }
                    label={
                      <Typography sx={{ fontFamily: "Montserrat" }}>
                        I agree to the Terms and Conditions
                      </Typography>
                    }
                  />
                </Tooltip>
              </Box>
            </Box>

            <div>
              <Divider hidden />
              <Button
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                }}
                variant="contained"
                disabled={!signupState.isAcceptedTC}
                onClick={() => {
                  void acceptTerms();
                }}
              >
                Next Step
              </Button>
            </div>
          </div>
        )}
        {signupState.currentStep === AmbassadorSignUpStep.SUBMIT && (
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ fontFamily: "Montserrat" }} variant={"h5"}>
              Welcome ♥
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }} variant={"body1"}>
              Here's your{" "}
              <Typography sx={{ fontFamily: "Montserrat" }} variant={"body1"}>
                <a
                  href={signupState.ambLink}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  link
                </a>
              </Typography>
              .
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }} variant={"body1"}>
              Head to your{" "}
              <Typography sx={{ fontFamily: "Montserrat" }} variant={"body1"}>
                <a href={`/ambassador`} target={"_blank"} rel={"noreferrer"}>
                  dashboard
                </a>
              </Typography>
              .
            </Typography>
          </Box>
        )}
        {signupState.currentStep === AmbassadorSignUpStep.W9 && (
          <div>
            {data.w9 ? (
              <Message success>
                <Message.Header>
                  <Typography sx={{ fontFamily: "Montserrat" }}>
                    Uploaded!
                  </Typography>
                </Message.Header>
                <Message.Content>
                  <Typography sx={{ fontFamily: "Montserrat" }}>
                    Click next to continue
                  </Typography>
                </Message.Content>
              </Message>
            ) : (
              <></>
            )}
            <Typography
              variant={"h6"}
              sx={{ fontFamily: "Montserrat", marginTop: 2 }}
            >
              Upload W-9 form to accept payment.
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat", marginTop: 2 }}>
              <a
                href={"https://content.bidmyvid.com/affiliates/w9.pdf"}
                target={"_blank"}
                rel={"noreferrer"}
              >
                Download W-9
              </a>
            </Typography>
            <Box sx={{ marginTop: 2 }}>
              <Input
                type={"file"}
                onChange={(e) => {
                  setSignupState((draft) => {
                    if (e.target.files && e.target.files.length > 0) {
                      draft.w9Attachment = e.target.files[0];
                    }
                  });
                }}
              ></Input>
            </Box>

            <div>
              <Divider hidden />
              {signupState.uploadProgress && (
                <Progress percent={signupState.uploadProgress} autoSuccess />
              )}
              <Divider hidden />
              <Button
                variant={"outlined"}
                sx={{ fontFamily: "Montserrat", marginRight: 2 }}
                disabled={signupState.isUploading}
                onClick={uploadW9}
              >
                Upload
              </Button>
              <Button
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                }}
                variant="contained"
                disabled={!signupState.isW9Uploaded}
                onClick={() => {
                  setSignupState((draft) => {
                    draft.completedSteps.push(AmbassadorSignUpStep.W9);
                    draft.currentStep = AmbassadorSignUpStep.SUBMIT;
                  });
                  (async () => {
                    await DataStore.save(
                      Ambassador.copyOf(data, (updated) => {
                        updated.approvedAt = new Date().toISOString();
                        updated.isApproved = true;
                      })
                    );
                  })();
                }}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </Page>
    );
  }
  return (
    <Page>
      <Typography variant="h1" sx={{ fontFamily: "Montserrat" }}>
        HOW DOES IT WORK?
      </Typography>
      <Typography variant="h4" sx={{ fontFamily: "Montserrat" }}>
        Enroll and Earn, it’s that easy!
      </Typography>
      <ol>
        <Typography variant="body1" sx={{ fontFamily: "Montserrat" }} as={"li"}>
          Complete the Opportunity Ambassador Program Agreement
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: "Montserrat" }} as={"li"}>
          Complete your W-9 Form
        </Typography>
      </ol>
      <Typography variant="h4" sx={{ fontFamily: "Montserrat" }}>
        Start Earning now and later!
      </Typography>
      <ul>
        <Typography variant="body1" sx={{ fontFamily: "Montserrat" }} as={"li"}>
          Once enrolled you will receive the following:
          <ul>
            <Typography
              variant="body1"
              sx={{ fontFamily: "Montserrat" }}
              as={"li"}
            >
              Ambassador Landing Page
              <ul>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "Montserrat" }}
                  as={"li"}
                >
                  Enrollment Form
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "Montserrat" }}
                  as={"li"}
                >
                  Payment Link
                </Typography>
              </ul>
            </Typography>
          </ul>
        </Typography>
      </ul>
      <Typography variant="body1" sx={{ fontFamily: "Montserrat" }} as={"li"}>
        Earn by directly enrolling from your Ambassador Page!
        <ul>
          <Typography
            variant="body1"
            sx={{ fontFamily: "Montserrat" }}
            as={"li"}
          >
            Each Service Provider who enrolls through your Ambassador Page that
            successfully completes the Enrollment Form and Remits the “ONE TIME”
            Invoice Payment through your “Buy a PASS Today!” button/link creates
            your reward that pays year after year!
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontFamily: "Montserrat" }}
            as={"li"}
          >
            The Platinum Annual Software Subscription (PASS) costs $500.00 per
            year. This equates to $1.37 per day when averaged over 365 days.
          </Typography>
        </ul>
      </Typography>

      <Button
        sx={{ fontSize: 18, fontFamily: "Montserrat", fontWeight: "600" }}
        variant="contained"
        onClick={startSignUp}
      >
        Get Started!
      </Button>
      <Divider hidden className="bottom-page-spacer" />
    </Page>
  );
};

export default AmbassadorSignUpPage;
