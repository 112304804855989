import React, { useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Container, Loader } from "semantic-ui-react";
import ProfilePhoto from "../components/ProfilePhoto";
import { UserContext } from "../components/UserContext";
import { pageStyle } from "../constants";
import useUser from "../hooks/use-user";

const Profile = () => {
  const { userId } = useParams();
  const { user, isFetchingUser } = useUser(userId);
  if (isFetchingUser) {
    return <Loader hidden />;
  }
  return (
    <Container style={pageStyle.mainContainer}>
      <ProfilePhoto user={user} />
    </Container>
  );
};
export default Profile;
