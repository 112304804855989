// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const SubscriptionType = {
  "MONTHLY": "Monthly",
  "YEARLY": "Yearly",
  "PLATINUM": "Platinum"
};

const ServiceProviderApplicationStatus = {
  "IN_PROGRESS": "InProgress",
  "SUBMITTED": "Submitted",
  "REVIEWING": "Reviewing",
  "APPROVED": "Approved",
  "DENIED": "Denied"
};

const WorkLength = {
  "YEAR_OR_LESS": "YearOrLess",
  "MORE_THAN_A_YEAR": "MoreThanAYear"
};

const FaqCategory = {
  "GENERAL": "General",
  "JOBS": "Jobs",
  "SERVICES": "Services",
  "CONTRACTORS": "Contractors"
};

const ActionLocationType = {
  "NEW_BID": "NewBid",
  "UPDATE_BID": "UpdateBid",
  "MESSAGE_TO_CLIENT": "MessageToClient",
  "MESSAGE_TO_SERVICE_PROVIDER": "MessageToServiceProvider",
  "ACCEPT_BID": "AcceptBid",
  "COMPLETE_JOB": "CompleteJob",
  "CONFIRM_AVAILABILITY": "ConfirmAvailability"
};

const InvoiceStatus = {
  "DRAFT": "DRAFT",
  "NEW": "NEW",
  "PENDING": "PENDING",
  "ACTIVE": "ACTIVE",
  "REQUESTED": "REQUESTED",
  "CLIENT_PAID": "CLIENT_PAID",
  "SENT": "SENT",
  "COMPLETE": "COMPLETE"
};

const InvoiceActivityAction = {
  "CREATED": "CREATED",
  "EDITED": "EDITED",
  "SENT_TO_CLIENT": "SENT_TO_CLIENT",
  "CLIENT_VIEWED": "CLIENT_VIEWED",
  "CLIENT_SIGNED": "CLIENT_SIGNED",
  "CLIENT_SENT_PAYMENT": "CLIENT_SENT_PAYMENT",
  "SENT_REMINDER": "SENT_REMINDER",
  "COMPLETED_INVOICE": "COMPLETED_INVOICE"
};

const { Job, Ambassador, SubscriptionPayment, JobAttachment, Notification, Bid, Message, ClientContact, User, ServiceProviderApplication, BasicInfo, BusinessLicense, Insurance, Certificate, ProvideService, WorkHistory, BankInfo, JobService, CustomerHelp, FAQs, GiftCard, GiftCardSubscription, Invoice, InvoiceLineItem, InvoiceClient, InvoiceClientAddress, InvoiceActivity, InvoiceTaxItem, ActionLocationItem, S3Object } = initSchema(schema);

export {
  Job,
  Ambassador,
  SubscriptionPayment,
  JobAttachment,
  Notification,
  Bid,
  Message,
  ClientContact,
  User,
  ServiceProviderApplication,
  BasicInfo,
  BusinessLicense,
  Insurance,
  Certificate,
  ProvideService,
  WorkHistory,
  BankInfo,
  JobService,
  CustomerHelp,
  FAQs,
  GiftCard,
  GiftCardSubscription,
  Invoice,
  InvoiceLineItem,
  InvoiceClient,
  InvoiceClientAddress,
  InvoiceActivity,
  InvoiceTaxItem,
  ActionLocationItem,
  SubscriptionType,
  ServiceProviderApplicationStatus,
  WorkLength,
  FaqCategory,
  ActionLocationType,
  InvoiceStatus,
  InvoiceActivityAction,
  S3Object
};