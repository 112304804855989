// @ts-nocheck
import { API, DataStore } from "aws-amplify";
import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { UserContext } from "../components/UserContext";
import { getEnvUrl } from "../constants";
import useOneSignalApi from "../hooks/use-one-signal";
import {
  ServiceProviderApplicationStatus,
  SubscriptionPayment,
} from "../models";
import { ServiceProviderApplication } from "../models";
const PaymentSuccess = () => {
  const { user } = useContext(UserContext);
  const navigation = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const oneSignalApi = useOneSignalApi();
  const _sessionId = searchParams.get("session_id");
  const _account = searchParams.get("account");
  const [state, setState] = useImmer({
    sessionData: undefined,
  });
  useEffect(() => {
    const getCheckoutSession = async (id: string) => {
      const getSessionResponse = await API.get(
        "api5ea1733e",
        `/stripe-connect/session/${id}`,
        { headers: {} }
      );
      console.log(JSON.stringify(getSessionResponse, null, 2));
      setState((draft) => {
        draft.sessionData = getSessionResponse;
      });
    };
    const sessionId = searchParams.get("session_id");
    console.log(JSON.stringify(searchParams));
    if (sessionId) {
      void getCheckoutSession(sessionId);
    }
  }, [searchParams]);

  useEffect(() => {
    const approveApplication = async (userId: string) => {
      const response = await DataStore.query(ServiceProviderApplication, (sp) =>
        sp.serviceProviderApplicationOwnerId.eq(userId)
      );
      if (response.length > 0) {
        await DataStore.save(
          ServiceProviderApplication.copyOf(response[0], (updated) => {
            updated.status = ServiceProviderApplicationStatus.APPROVED;
          })
        );
        await DataStore.save(
          new SubscriptionPayment({
            userId,
            ambassadorId: user.ambRefId,
            isLifetime: true,
            amount: `${state.sessionData.amount_total}`,
            method: "stripe",
            paymentRefId: state.sessionData.id,
          })
        );
        await oneSignalApi.setTags(user, {
          isServiceProvider: "true",
          lifeTimeInProgress: "",
        });
        window.location.replace(getEnvUrl() + "/contractor");
      }
    };
    if (state.sessionData) {
      const userId = state.sessionData.client_reference_id;
      void approveApplication(userId);
    }
  }, [state.sessionData]);
  //   console.log(JSON.stringify(searchParams));

  return (
    <div>
      <p>{JSON.stringify(searchParams, null, 2)}</p>
      <p>{_sessionId}</p>
      {state.sessionData && JSON.stringify(state.sessionData, null, 2)}
    </div>
  );
};

export default PaymentSuccess;
