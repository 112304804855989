import React, { useState } from "react";
import { Auth, DataStore, Hub } from "aws-amplify";
import {
  TextField,
  Snackbar,
  Alert,
  Stack,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import {
  Input,
  Button,
  Divider,
  Message,
  Form,
  Header,
  Container,
} from "semantic-ui-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useLocation } from "react-router-dom";
import { capitalizeFirstLetter, pageStyle } from "../constants";
import { useEffect } from "react";
import { User } from "../models";
const LoginForm = ({
  loginSuccess,
  goToSignUp,
  goToForgotPassword,
}: {
  loginSuccess: () => void;
  goToSignUp: () => void;
  goToForgotPassword: () => void;
}) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const login = async () => {
    setIsLoading(true);
    try {
      const response = await Auth.signIn({
        username: capitalizeFirstLetter(email),
        password: password,
      });
      //console.log(JSON.stringify(response));

      setIsLoggedIn(true);
      await DataStore.start();

      return;
    } catch (e) {}
    try {
      const response = await Auth.signIn({
        username: email.toLowerCase(),
        password: password,
      });

      setIsLoggedIn(true);
      DataStore.start();
    } catch (e) {
      const error = e as Error;
      if (error.message) {
        //console.log(error.message);
        setIsLoading(false);
        setErrorMessage(error.message);
        setShowErrorAlert(true);
      }
    }
  };
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       await Auth.currentAuthenticatedUser();
  //       if (location.state.redirect) {
  //         navigation(location.state.redirect, { replace: true });
  //       } else {
  //         navigation("/", { replace: true });
  //       }
  //     } catch (e) {}
  //   })();
  // }, [location, navigation]);
  useEffect(() => {
    //console.log("Is Logged In!");
    if (isLoggedIn) {
      const listener = Hub.listen("datastore", (hubData) => {
        const { event, data } = hubData.payload;
        // //console.log("[+] " + event + " " + JSON.stringify(data));
        if (event == "ready") {
          setIsLoading(false);
          //console.log("Redirect from login");
          if (redirectPath) {
            // //console.log("Redirect from login" + location.state.redirect);
            navigation(redirectPath, { replace: true });
          } else {
            //console.log("Navigate to home page /");
            navigation("/", { replace: true });
          }

          loginSuccess();
        }
      });
      return () => {
        listener();
      };
    }
  }, [isLoggedIn, loginSuccess, navigation, redirectPath]);
  useEffect(() => {
    if (location.state && location.state.redirect) {
      setRedirectPath(location.state.redirect);
    }
  }, [location]);
  return (
    <Container style={pageStyle.mainContainer}>
      <Typography fontFamily={"Montserrat"} variant={"h3"} fontWeight={"700"}>
        Login
      </Typography>
      <Box sx={{ marginY: "2em" }}>
        <Typography fontFamily={"Montserrat"}>
          <Form
            onSubmit={() => {
              void login();
            }}
            error={showErrorAlert}
          >
            <Form.Group widths={"equal"}>
              <Form.Field>
                <Form.Input
                  error={showErrorAlert}
                  label={"Email"}
                  value={email}
                  size={"large"}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder={"Email"}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  error={showErrorAlert}
                  label={"Password"}
                  type={"password"}
                  size={"large"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                ></Form.Input>
              </Form.Field>
            </Form.Group>
            <Form.Button loading={isLoading} color={"red"}>
              Sign in
            </Form.Button>
            <Form.Button
              onClick={() => {
                goToSignUp();
              }}
            >
              Don't have an account? Click here!
            </Form.Button>
            <Form.Button
              onClick={() => {
                goToForgotPassword();
              }}
            >
              Forgot password
            </Form.Button>
            <Message
              onDismiss={() => {
                setShowErrorAlert(false);
              }}
              error
            >
              <Message.Header>Failed to sign in</Message.Header>
              <Message.Content>{errorMessage}</Message.Content>
            </Message>
          </Form>
        </Typography>
      </Box>

      {/* <Snackbar
        open={showErrorAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => {
          setShowErrorAlert(false);
        }}
      >
        <Alert variant={"filled"} severity={"error"}>
          {errorMessage}
        </Alert>
      </Snackbar> */}
    </Container>
  );
};

export default LoginForm;
