import { DataStore } from "aws-amplify";
import { useContext } from "react";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { UserContext } from "../components/UserContext";
import { ProvideService } from "../models";

const useProvidedServices = () => {
  const { user } = useContext(UserContext);
  const [state, setState] = useImmer({
    isFetching: true,
    providedServices: [],
  });
  useEffect(() => {
    const fetchData = async () => {
      const response = await DataStore.query(ProvideService, (ps) =>
        ps.provideServiceOwnerId.eq(user.id)
      );
      setState((draft) => {
        console.log(JSON.stringify(response));
        draft.providedServices = response;
        draft.isFetching = false;
      });
    };
    if (state.isFetching && user) {
      void fetchData();
    }
  }, [state.isFetching, setState, user]);
  return {
    isFetchingProvidedServices: state.isFetching,
    providedServices: state.providedServices,
  };
};

export default useProvidedServices;
