import {
  Form,
  Header,
  Container,
  Button,
  Message,
  Grid,
} from "semantic-ui-react";
import ReactPlayer from "react-player";
import { Typography, Card, CardContent, Divider } from "@mui/material";
import { Colors, pageStyle, registerUserWithRevenueCat } from "../constants";
import Page from "../components/Page";

const OnboardingData: {
  title: string;
  description: string;
  videoUrl: string;
  thumbnail: string;
}[] = [
  {
    title: "Service Provider Link",
    description: "Understand how to share your service provider link.",
    videoUrl:
      "https://content.bidmyvid.com/service-provider-onboarding/ServiceProviderLink.mp4",
    thumbnail:
      "https://content.bidmyvid.com/service-provider-onboarding/spl-cover-photo.png",
  },
  {
    title: "Service Provider Profile",
    description: "See how your profile will look to others.",
    videoUrl:
      "https://content.bidmyvid.com/service-provider-onboarding/ServiceProviderProfile.mp4",
    thumbnail:
      "https://content.bidmyvid.com/service-provider-onboarding/sp-cover-photo.png",
  },
  {
    title: "Job Details",
    description: "Familiarize yourself with the job board.",
    videoUrl:
      "https://content.bidmyvid.com/service-provider-onboarding/JobDetails.mp4",
    thumbnail:
      "https://content.bidmyvid.com/service-provider-onboarding/jd-cover-photo.png",
  },
  {
    title: "Create a Bid",
    description: "Here's an example of creating a bid for a job.",
    videoUrl:
      "https://content.bidmyvid.com/service-provider-onboarding/CreatingaBid.mp4",
    thumbnail:
      "https://content.bidmyvid.com/service-provider-onboarding/cb-cover-photo.png",
  },
  {
    title: "Confirming Availability",
    description: "Understand the next step after your bid is accepted.",
    videoUrl:
      "https://content.bidmyvid.com/service-provider-onboarding/ConfirmingAvailability.mp4",
    thumbnail:
      "https://content.bidmyvid.com/service-provider-onboarding/ca-cover-photo.png",
  },
  {
    title: "Before and After Photos",
    description:
      "Find out the importance of before and after photos for each one of your completed jobs on the platform.",
    videoUrl:
      "https://content.bidmyvid.com/service-provider-onboarding/BeforeandAfterPhotos.mp4",
    thumbnail:
      "https://content.bidmyvid.com/service-provider-onboarding/ba-cover-photo.png",
  },
];
const ServiceProviderOnboarding = () => {
  return (
    <Page>
      <Typography sx={{ fontFamily: "Montserrat" }} variant={"h3"}>
        Service Provider Onboarding
      </Typography>

      {OnboardingData.map((item) => {
        return (
          <Card key={item.title} sx={{ mt: 2 }}>
            <CardContent>
              <Typography
                sx={{ fontFamily: "Montserrat", mb: 1 }}
                variant={"h5"}
              >
                {item.title}
              </Typography>
              <Divider light />
              <Typography
                sx={{ fontFamily: "Montserrat", mt: 1 }}
                variant={"body1"}
              >
                {item.description}
              </Typography>
              <ReactPlayer light={item.thumbnail} playsinline={true} url={item.videoUrl} controls width={"100%"} />
            </CardContent>
          </Card>
        );
      })}
      <Typography
        sx={{ fontFamily: "Montserrat", marginTop: 2, marginBottom: 2 }}
      >
        Next step, download the app and finish setting up your profile!
      </Typography>
      <Button
        onClick={() => {
          window.open("https://bidmyvid.app.link/jzt3fPMkqub");
        }}
        basic
        color={"red"}
      >
        Download App
      </Button>
    </Page>
  );
};
export default ServiceProviderOnboarding;
