import { useState, useEffect, useContext } from "react";
import { DataStore, Logger } from "aws-amplify";
import { Job, JobAttachment, User } from "../models";
import useJobBids from "./use-job-bids";
import useJobMessages from "./use-job-messages";
import { OpType } from "@aws-amplify/datastore";
import { UserContext } from "../components/UserContext";
const logger = new Logger("useJob");
export default function useJob(id) {
  const { user } = useContext(UserContext);
  const [job, setJob] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [serviceProvider, setServiceProvider] = useState();
  const [isServiceProvider, setIsServiceProvider] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [owner, setOwner] = useState();
  const { bids, isFetchingBids, uniqueJobBidListId } = useJobBids(id);
  const { messages, isFetchingMessages, refreshMessages } = useJobMessages(id);
  const refreshJob = () => {
    setIsFetching(true);
  };
  useEffect(() => {
    if (isFetching && id && user) {
      (async () => {
        try {
          const jobData = await DataStore.query(Job, id);
          if (!jobData) {
            setIsFetching(false);
            throw new Error("Failed to fetch job");
          }
          if (jobData.jobOwnerId === user.id) {
            setIsOwner(true);
            setOwner(user);
          } else {
            const fetchedOwner = await DataStore.query(
              User,
              jobData.jobOwnerId
            );
            setOwner(fetchedOwner);
          }
          setJob(jobData);
          const fetchedAttachments = await DataStore.query(
            JobAttachment,
            (ja) => ja.jobAttachmentJobId.eq(id)
          );
          setAttachments(fetchedAttachments);
          if (jobData.jobServiceProviderId) {
            if (jobData.jobServiceProviderId === user.id) {
              setIsServiceProvider(true);
            }
            const serviceProvider = await DataStore.query(
              User,
              jobData.jobServiceProviderId
            );
            if (serviceProvider) {
              setServiceProvider(serviceProvider);
            }
          }
        } catch (e) {
          logger.warn("Failed to fetch job " + id, e);
        }
        setIsFetching(false);
      })();
    }
  }, [id, isFetching, user]);
  useEffect(() => {
    const subscription = DataStore.observe(Job, id).subscribe((msg) => {
      //   console.log(msg.model, msg.opType, msg.element);
      setJob(msg.element);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (job) {
      const subscription = DataStore.observe(JobAttachment, (j) =>
        j.jobAttachmentJobId.eq(job.id)
      ).subscribe((msg) => {
        //   console.log(msg.model, msg.opType, msg.element);
        console.log(msg.model, msg.opType, msg.element);
        if (msg.opType === OpType.INSERT) {
          setAttachments((msgs) =>
            [...msgs, msg.element].sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
          );
        }
        if (msg.opType === OpType.UPDATE) {
          setAttachments((msgs) => [
            ...msgs
              .map((existingMessage) =>
                existingMessage.id !== msg.element.id
                  ? existingMessage
                  : msg.element
              )
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              ),
          ]);
        }
        if (msg.opType === OpType.DELETE) {
          setAttachments((msgs) => [
            ...msgs
              .filter((m) => m.id !== msg.element.id)
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              ),
          ]);
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [job]);
  return {
    job,
    isOwner,
    owner,
    serviceProvider,
    isServiceProvider,
    attachments,
    bids,
    uniqueJobBidListId,
    isFetchingBids,
    isFetching,
    messages,
    isFetchingMessages,
    refreshJob,
    refreshMessages,
  };
}
