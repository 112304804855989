import {
  Container,
  Divider,
  Form,
  Message,
  List,
  Header,
  Button,
} from "semantic-ui-react";
import ReactPlayer from "react-player";

import { SubscriptionPayment, SubscriptionType, User } from "../models";
import { getEnvUrl, pageStyle } from "../constants";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import branch from "branch-sdk";
import { Typography } from "@mui/material";
import useIsSubscribed from "../hooks/use-is-subscribe";
const ShareJobPreview = () => {
  const [params, setSearchParams] = useSearchParams();
  const isSubscribed = useIsSubscribed();
  const jobId = params.get("jobId")
    ? decodeURIComponent(params.get("jobId"))
    : null;

  const videoKey = decodeURIComponent(params.get("videoKey"));
  const service = params.get("service")
    ? decodeURIComponent(params.get("service"))
    : null;

  const description = params.get("description")
    ? decodeURIComponent(params.get("description"))
    : null;
  const videoUri = videoKey ? "https://content.bidmyvid.com/" + videoKey : "";
  const [branchLink, setBranchLink] = useState("");

  useEffect(() => {
    if (jobId) {
      (async () => {
        const deeplink = "bidmyvid://service/job/" + jobId;
        branch.link(
          {
            channel: "Job Preview",
            feature: "Job Preview Web App",

            tags: ["job preview"],
            data: {
              $ios_deeplink_path: deeplink,
              $deeplink_path: deeplink,
              $referenceUrl_iOS: deeplink,
            },
          },
          (err, link) => {
            if (err) {
            } else {
              setBranchLink(link);
            }
          }
        );
      })();
    }
  }, [jobId]);

  return (
    <Container style={pageStyle.mainContainer}>
      {videoUri != "" && (
        <>
          <ReactPlayer
            url={videoUri}
            controls
            playsinline
            width={"100%"}
            style={{ backgroundColor: "#000" }}
            height={window.innerWidth / 2}
          />
        </>
      )}
      <List>
        <List.Item>
          <List.Header>
            <Typography
              fontFamily="Montserrat"
              fontWeight={"700"}
              variant="body1"
            >
              {service ?? "Missing service"}
            </Typography>
          </List.Header>
          <List.Content>
            <Typography fontFamily="Montserrat" variant="body1">
              {description ?? "No description"}
            </Typography>
          </List.Content>
        </List.Item>
      </List>
      {!isSubscribed && (
        <div>
          <div style={{ textAlign: "center" }}>
            <div>
              <Typography
                fontFamily={"Montserrat"}
                fontWeight={"700"}
                fontSize={14}
                variant="overline"
              >
                Interested in submitting an estimate on this video?
              </Typography>
            </div>
          </div>
          <div style={{ backgroundColor: "#000", textAlign: "center" }}>
            <Typography
              sx={{ color: "#fff" }}
              fontFamily={"Montserrat"}
              fontWeight={"700"}
              fontSize={14}
              variant="overline"
            >
              Become powered by{" "}
              <Typography
                fontFamily={"Montserrat"}
                fontWeight={"700"}
                sx={{ color: "#fff" }}
                fontSize={14}
                variant="overline"
              >
                BidMyVid
              </Typography>
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              paddingTop: "0.3em",
              paddingBottom: "0.3em",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{ color: "#000" }}
              fontFamily={"Montserrat"}
              fontWeight={"700"}
              fontSize={14}
              variant="body1"
            >
              Unlimited video requests
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: "lightgray",
              paddingTop: "0.3em",
              paddingBottom: "0.3em",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{ color: "#000" }}
              fontFamily={"Montserrat"}
              fontWeight={"700"}
              fontSize={14}
              variant="body1"
            >
              Estimates in minutes
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              paddingTop: "0.3em",
              paddingBottom: "0.3em",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{ color: "#000" }}
              fontFamily={"Montserrat"}
              fontWeight={"700"}
              fontSize={14}
              variant="body1"
            >
              Simplified invoicing with merchant processing
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: "lightgray",
              paddingTop: "0.3em",
              paddingBottom: "0.3em",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{ color: "#000" }}
              fontFamily={"Montserrat"}
              fontWeight={"700"}
              fontSize={14}
              variant="body1"
            >
              Efficient scheduling
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: "#fff",

              paddingTop: "0.3em",
              paddingBottom: "0.3em",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{ color: "#000" }}
              fontFamily={"Montserrat"}
              fontWeight={"700"}
              fontSize={14}
              variant="body1"
            >
              Automated reputation management
            </Typography>
          </div>

          <Button
            color="red"
            onClick={() => {
              console.log("Navigate to subscribe");
              window.location.replace(getEnvUrl() + "/application/pay");
            }}
            size="large"
            fluid
          >
            Subscribe
          </Button>
        </div>
      )}

      {isSubscribed && jobId !== null && (
        <Button
          color="red"
          onClick={() => {
            console.log("Navigate to subscribe");
            window.location.replace(branchLink);
          }}
          size="large"
          fluid
        >
          Open in app
        </Button>
      )}
      <div style={{ height: 100 }}></div>
    </Container>
  );
};

export default ShareJobPreview;
