import { Container, Message } from "semantic-ui-react";
import { pageStyle } from "../constants";

const AmbassadorSubmissionStatus = ({ data }) => {
  if (data.isApproved) {
    return (
      <Container style={pageStyle.mainContainer}>
        <Message success>
          <Message.Header>Approved</Message.Header>
          <Message.Content>
            <p>Your submission to become an ambassador was approved! </p>
          </Message.Content>
        </Message>
      </Container>
    );
  }
  if (data.isDenied) {
    return (
      <Container style={pageStyle.mainContainer}>
        <Message error>
          <Message.Header>Denied</Message.Header>
          <Message.Content>
            <p>Your submission to become an ambassador was denied.</p>
            <p>
              Reason: <b>{data.deniedReason}</b>.
            </p>
          </Message.Content>
        </Message>
      </Container>
    );
  }
  return (
    <Container style={pageStyle.mainContainer}>
      <Message info>
        <Message.Header>In Review</Message.Header>
        <Message.Content>
          <p>Your submission to become an ambassador is in review!</p>
        </Message.Content>
      </Message>
    </Container>
  );
};
export default AmbassadorSubmissionStatus;
