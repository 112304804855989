import React, { useContext, useEffect, useState } from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { Container, Divider, Form, Message } from "semantic-ui-react";
import { SubscriptionPayment, SubscriptionType, User } from "../models";
import { pageStyle } from "../constants";
import { API, DataStore } from "aws-amplify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import useOneSignalApi from "../hooks/use-one-signal";
import useUser from "../hooks/use-user";
import useQuery from "../hooks/use-query";
import { Typography } from "@mui/material";
const PAYMENT_AMOUNT = 49925;
const ApplicationPayment = ({
  onComplete,
  onFailedPayment,
  processingPayment,
  price,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const oneSignalApi = useOneSignalApi();
  const navigation = useNavigate();
  const query = useQuery();
  const ambRefId = query.get("ambRefId");
  const [familyName, setFamilyName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const { user } = useContext(UserContext);
  const [paymentError, setPaymentError] = useState(undefined);

  return (
    <Container style={pageStyle.mainContainer}>
      {user && (
        <stripe-pricing-table
          pricing-table-id="prctbl_1MCNJeBUT851BikQnDKa1nRd"
          publishable-key="pk_live_51M9g06BUT851BikQGFUxQ1Tiv0r5Sb7vVeJXGBRvdH6o73Zcy9imPjuHQN67f2D6TvBDcnpJ4npMB8RpjICCGQcb00JAYYvtET"
          client-reference-id={user?.id}
          customer-email={user?.email}
        ></stripe-pricing-table>
      )}
    </Container>
  );
};

export default ApplicationPayment;
