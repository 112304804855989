import { makeStyles } from "@material-ui/core/styles";

import { Container, Table, Button, Card, List, Icon } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { DataStore, Predicates, Storage } from "aws-amplify";
import {
  BasicInfo,
  ServiceProviderApplication,
  ServiceProviderApplicationStatus,
  InvoiceLineItem,
  Invoice,
} from "./models";
import { displayUserFullName, getEnvUrl, pageStyle } from "./constants";
import Axios from "axios";
import branch from "branch-sdk";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function UsersTab({ users }) {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState();
  const [profilePhotoUri, setProfileUri] = useState();
  const [binfo, setBinfo] = useState();
  const [application, setApplication] = useState();
  const displayUsers = users.sort((a, b) => {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  useEffect(() => {
    if (selectedUser) {
      (async () => {
        //Retreive the photo to gather and size based on
        //the source. Behavior after getting the image
        //from the source is the same.
        var profilePhoto;
        if (selectedUser?.profilePhoto && selectedUser?.profilePhoto.key) {
          profilePhoto = selectedUser.profilePhoto;
        }

        if (profilePhoto && profilePhoto.key) {
          const response = await Storage.get(profilePhoto.key.substring(7));
          setProfileUri(response);
        }

        const info = await DataStore.query(BasicInfo, (b) =>
          b.basicInfoOwnerId.eq(selectedUser.id)
        );
        const app = await DataStore.query(ServiceProviderApplication, (sp) =>
          sp.serviceProviderApplicationOwnerId.eq(selectedUser.id)
        );
        if (app.length > 0) {
          setApplication(app[0]);
        }
        //console.log(JSON.stringify(app));
        //console.log(JSON.stringify(selectedUser));
        //console.log(JSON.stringify(info));
        if (info.length > 0) {
          setBinfo(info[0]);
        }
      })();
    } else {
      setBinfo(null);
      setApplication(null);
      setProfileUri(null);
    }
  }, [selectedUser]);
  return (
    <Container style={pageStyle.mainContainer}>
      {!selectedUser && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Created at</Table.HeaderCell>
              <Table.HeaderCell>First name</Table.HeaderCell>
              <Table.HeaderCell>Last name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Phone number</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {displayUsers.map((user) => (
              <Table.Row key={user.id}>
                <Table.Cell align="left">
                  <Button
                    basic
                    onClick={() => {
                      setSelectedUser(user);
                    }}
                  >
                    {user.id}
                  </Button>
                </Table.Cell>
                <Table.Cell align="right">
                  {new Date(user.createdAt).toDateString()}
                </Table.Cell>
                <Table.Cell align="right">{user.firstName}</Table.Cell>
                <Table.Cell align="right">{user.lastName}</Table.Cell>
                <Table.Cell align="right">{user.email}</Table.Cell>
                <Table.Cell align="right">{user.phoneNumber}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
      {selectedUser && (
        <div>
          <Button
            attached={"top"}
            onClick={() => {
              setSelectedUser(null);
            }}
          >
            Back to users
          </Button>

          <Card fluid>
            <Card.Content>
              <Card.Header>
                {[selectedUser.firstName, selectedUser.lastName].join(" ")}
              </Card.Header>
              <Card.Meta>{selectedUser.id}</Card.Meta>
              <Card.Description>
                {binfo && (
                  <List verticalAlign="middle">
                    <List.Item>
                      <List.Content floated="right">
                        <Button size="tiny" basic circular icon>
                          <Icon name={"copy"}></Icon>
                        </Button>
                      </List.Content>
                      <List.Icon name="mail" />

                      <List.Content>{binfo.workEmail}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="building" />
                      <List.Content>{binfo.companyName}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Button size="tiny" basic circular icon>
                          <Icon name={"copy"}></Icon>
                        </Button>
                      </List.Content>
                      <List.Icon name="marker" />
                      <List.Content>
                        {binfo.address + "\n"}
                        {binfo.address2 ? binfo.address2 + "\n" : ""}
                        {binfo.city},{binfo.state + "\n"}
                        {binfo.zip}
                      </List.Content>
                    </List.Item>
                  </List>
                )}
                {!binfo && <p>No basic info</p>}
              </Card.Description>
            </Card.Content>
            <Card.Content extra></Card.Content>
          </Card>
          {application &&
            application.status ===
              ServiceProviderApplicationStatus.APPROVED && (
              <Button
                onClick={() => {
                  branch.link(
                    {
                      feature: "profile link share",
                      channel: "web",
                      data: {
                        $og_description:
                          "Need work done? Send a video directly to me.",
                        $og_image_url: profilePhotoUri ?? null,
                        $og_title: `${displayUserFullName(
                          selectedUser
                        )} Profile`,
                        $ios_deeplink_path: `bidmyvid://service/profile/${selectedUser.id}`,
                        $ios_url: `bidmyvid://service/profile/${selectedUser.id}`,
                        // $referenceUrl_android: `bidmyvid://service/profile/${selectedUser.id}`,
                        $desktop_url: `${getEnvUrl()}/profile/${
                          selectedUser.id
                        }`,
                        $canonical_url: getEnvUrl(),
                      },
                    },
                    async (err, link) => {
                      if (link) {
                        await navigator.clipboard.writeText(link);
                      }
                    }
                  );
                  // (async () => {
                  //   const response = await Axios.post(
                  //     "https://api2.branch.io/v1/url",
                  //     {
                  //       branch_key: "key_live_je1dTuIPVv4guvzfd1RMPiaatvanq9yq",
                  //       "~feature": "profile link share",
                  //       data: {
                  //         $referenceUrl_iOS: `bidmyvid://service/profile/${binfo.basicInfoOwnerId}`,
                  //         $referenceUrl_android: `bidmyvid://service/profile/${binfo.basicInfoOwnerId}`,
                  //         $desktop_url: `https://app.bidmyvid.com/profile/${binfo.basicInfoOwnerId}`,
                  //         $canonical_url: "https://app.bidmyvid.com",
                  //       },
                  //     },
                  //     { headers: { "Content-Type": "application/json" } }
                  //   );
                  //   navigator.clipboard.writeText(response.data.url);
                  // })();
                }}
              >
                Contractor Profile Link
              </Button>
            )}
        </div>
      )}
    </Container>
  );
}
