import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Divider,
  Feed,
  Header,
  Input,
  Label,
  List,
  Loader,
} from "semantic-ui-react";
import { useImmer } from "use-immer";
import Avatar from "../components/Avatar";
import JobMessage from "../components/JobMessage";
import JobVideo from "../components/JobVideo";
import ProfilePhoto from "../components/ProfilePhoto";
import { pageStyle } from "../constants";
import useJob from "../hooks/use-job";

const JobDetails = () => {
  const params = useParams();
  const id = params["id"];
  const jobDetails = useJob(id);
  const [jobDetailsInfo, setJobDetailsInfo] = useImmer({
    data: undefined,
    isDataFetched: false,
    isFetchingData: true,
  });
  if (jobDetails.isFetching) {
    return <Loader active={jobDetails.isFetching} />;
  }
  return (
    <Container style={pageStyle.mainContainer}>
      <JobVideo job={jobDetails.job} />
      <Header>
        <Avatar userId={jobDetails.owner?.id} />
      </Header>
      <List>
        <List.Item>
          <List.Content>{jobDetails.job.service}</List.Content>
          <List.Content>{jobDetails.job.description}</List.Content>
        </List.Item>
      </List>
      <Header as={"h3"}>Job board</Header>
      <Divider></Divider>
      <Feed>
        {jobDetails.messages.map((message) => {
          return <JobMessage key={message.id} messageId={message.id} />;
        })}
      </Feed>
      <Input placeholder={"Enter message"} />
      <Header as={"h3"}>Bids</Header>
      <Divider></Divider>
    </Container>
  );
};
export default JobDetails;
