import React, { useState, useContext, useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import useIsSubscribed from "../hooks/use-is-subscribe";
import useSubscriptionPayment from "../hooks/use-subscription-payment";
import { UserContext } from "./UserContext";

const AuthRoute = ({ children, rest, isSubscribed, isEmployee }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [isSignedIn, setIsSignedIn] = useState(undefined);
  const { user, isFetchingUser } = useContext(UserContext);
  const isActiveSubscription = useIsSubscribed();
  useEffect(() => {
    if (
      isActiveSubscription !== undefined &&
      isSubscribed !== undefined &&
      isSubscribed !== isActiveSubscription
    ) {
      navigation("/");
    }
  }, [isActiveSubscription]);
  if (user === undefined && isFetchingUser === false) {
    //console.log(location.pathname + location.search);

    return (
      <Navigate
        to={"/login"}
        state={{ redirect: location.pathname + location.search }}
      />
    );
  }
  if (user === undefined && isFetchingUser) {
    return (
      <>
        <Loader active></Loader>
      </>
    );
  }
  if (user && isEmployee === true && user.email.includes("@bidmyvid.com")) {
    return <>{children}</>;
  } else if (isEmployee === true)
  if (
    user &&
    isSubscribed !== undefined &&
    isSubscribed === isActiveSubscription
  ) {
    return <>{children}</>;
  }
  if (user) {
    return <>{children}</>;
  }
};
export default AuthRoute;
