import { createContext, useEffect } from "react";
import useOneSignalApi from "../hooks/use-one-signal";
import useUser from "../hooks/use-user";

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const { user, isFetchingUser } = useUser();

  return (
    <UserContext.Provider value={{ user, isFetchingUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
