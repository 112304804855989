import { useState, useEffect } from "react";
import {
  BasicInfo,
  BusinessLicense,
  Certificate,
  Insurance,
  ProvideService,
  ServiceProviderApplication,
  ServiceProviderApplicationStatus,
  User,
  WorkHistory,
} from "../models";
import { DataStore, Auth, Logger } from "aws-amplify";
import { OpType } from "@aws-amplify/datastore";
const logger = new Logger("UserHook");
export const RequiredProfileSections = {
  basicInfo: "basicInfo",
  insurance: "insurance",
  workHistory: "workHistory",
};
export default function useServiceProvider(userId) {
  const [user, setUser] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [application, setApplication] = useState();
  const [basicInfo, setBasicInfo] = useState();
  const [businessLicense, setBusinessLicense] = useState();
  const [insurance, setInsurance] = useState();
  const [workHistory, setWorkHistory] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [provideServices, setProvideServices] = useState([]);
  const [sectionsToFinish, setSectionsToFinish] = useState([]);

  /**
   * Notes for tomorrow
   * In this hook (or decide if the code should live by its own)
    fetchBasicInfo()
    If basic info object is returned
      check for company name exists
      check for company phone number exists
      check for email
      check for company mailing address
    Else
      Need to add to list of things contractor needs to fill out

    fetchInsurance()
    If insurance object is returned
      check insurance id exists
      check insurance photo exists
    Else
      Need to add to list of things contractor needs to fill out

    fetchWorkHistory()
    If work history objects are returned
      At a minimum:
      - check phone number
      - check insurance photo exists
    Else
      Need to add to list of things contractor needs to fill out
   *  
      Notes also on UI
      Create a BidButton component that makes use of this logic to determine if they can bid
      reuse the logic of the original application page to direct service provider to update their info

   */
  useEffect(() => {
    logger.log("is fetching sp", isFetching);
  }, [isFetching]);
  useEffect(() => {
    // logger.log("mike userid" + userId, isFetching);
    if (userId && isFetching) {
      // logger.log("mike userid inside" + userId);
      (async () => {
        const addSectionToFinish = (section) => {
          if (!sectionsToFinish.includes(section)) {
            setSectionsToFinish((prev) => [...prev, section]);
          }
        };
        try {
          // fetch user data first
          const userData = await DataStore.query(User, userId);
          setUser(userData);

          // fetch application
          const applications = await DataStore.query(
            ServiceProviderApplication,
            (sapp) => sapp.serviceProviderApplicationOwnerId.eq(userId)
          );

          if (applications.length > 0) {
            setApplication(applications[0]);
          }

          // fetch basic info
          const basicInfoList = await DataStore.query(BasicInfo, (bi) =>
            bi.basicInfoOwnerId.eq(userId)
          );
          if (basicInfoList.length === 1) {
            /**
             *  fetchBasicInfo()
    If basic info object is returned
      check for company name exists
      check for company phone number exists
      check for email
      check for company mailing address
    
             */
            const basicInfoObject = basicInfoList[0];
            if (
              !!basicInfoObject.companyName &&
              !!basicInfoObject.workEmail &&
              !!basicInfoObject.address &&
              !!basicInfoObject.city &&
              !!basicInfoObject.state &&
              !!basicInfoObject.zip
            ) {
              // has all the required info filled out
            } else {
              addSectionToFinish(RequiredProfileSections.basicInfo);
            }
            setBasicInfo(basicInfoObject);
          } else if (basicInfoList.length === 0) {
            /**
             * Else
      Need to add to list of things contractor needs to fill out
             */
            addSectionToFinish(RequiredProfileSections.basicInfo);
          }

          //fetch business license
          const businessLicenseList = await DataStore.query(
            BusinessLicense,
            (bl) => bl.businessLicenseOwnerId.eq(userId)
          );
          if (businessLicenseList.length === 1) {
            setBusinessLicense(businessLicenseList[0]);
          }

          //fetch insurance
          const insuranceList = await DataStore.query(Insurance, (i) =>
            i.insuranceOwnerId.eq(userId)
          );
          if (insuranceList.length === 1) {
            const insuranceObject = insuranceList[0];
            if (insuranceObject.insuranceId && insuranceObject.insurancePhoto) {
              // has required insurance filled out
            } else {
              addSectionToFinish(RequiredProfileSections.insurance);
            }
            setInsurance(insuranceObject);
          } else if (insuranceList.length === 0) {
            addSectionToFinish(RequiredProfileSections.insurance);
          }

          //fetch work history
          const workHistoryList = await DataStore.query(WorkHistory, (wh) =>
            wh.workHistoryOwnerId.eq(userId)
          );
          if (workHistoryList.length === 0) {
            addSectionToFinish(RequiredProfileSections.workHistory);
          }
          setWorkHistory(workHistoryList);

          //fetch certificates
          const certificates = await DataStore.query(Certificate, (c) =>
            c.certificateOwnerId.eq(userId)
          );
          setCertificates(certificates);

          //fetch services they provide
          const services = await DataStore.query(ProvideService, (ps) =>
            ps.provideServiceOwnerId.eq(userId)
          );
          setProvideServices(services);
        } catch (e) {
          logger.warn(
            "Issue fetching details for service provider from DataStore",
            e
          );
        }
        setIsFetching(false);
      })();
    }
  }, [userId, isFetching, sectionsToFinish]);

  useEffect(() => {
    //set up subscriptions to keep this hook updated!!

    if (userId) {
      // subscribe to application changes
      const removeSectionToFinish = (section) => {
        if (sectionsToFinish.includes(section)) {
          logger.info("is this happening");
          setSectionsToFinish((prev) => prev.filter((s) => s !== section));
        }
      };
      const applicationSubscription = DataStore.observe(
        ServiceProviderApplication,
        (sapp) => sapp.serviceProviderApplicationOwnerId.eq(userId)
      ).subscribe((msg) => {
        logger.log("test mike");
        // logger.log(
        //   "mike application subscription",
        //   msg.model,
        //   msg.opType,
        //   msg.element,
        // );
        setApplication(msg.element);
      });
      // subscribe to business license changes
      const businessLicenseSubscription = DataStore.observe(
        BusinessLicense,
        (bl) => bl.businessLicenseOwnerId.eq(userId)
      ).subscribe((msg) => {
        //console.log(msg.model, msg.opType, msg.element);
        setBusinessLicense(msg.element);
      });
      // subscribe to insurance changes
      const insuranceSubscription = DataStore.observe(Insurance, (i) =>
        i.insuranceOwnerId.eq(userId)
      ).subscribe((msg) => {
        // logger.log("mike", msg.model, msg.opType, msg.element);
        if (msg.opType === OpType.INSERT || msg.opType === OpType.UPDATE) {
          removeSectionToFinish(RequiredProfileSections.insurance);
        }
        setInsurance(msg.element);
      });
      // subscribe to other certs changes
      const otherCertsSubscription = DataStore.observe(Certificate, (c) =>
        c.certificateOwnerId.eq(userId)
      ).subscribe((msg) => {
        //console.log(msg.model, msg.opType, msg.element);
        if (msg.opType === OpType.INSERT) {
          setCertificates((certs) =>
            [...certs, msg.element].sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
          );
        }
        if (msg.opType === OpType.UPDATE) {
          setCertificates((certs) => [
            ...certs
              .map((existingCert) =>
                existingCert.id !== msg.element.id ? existingCert : msg.element
              )
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              ),
          ]);
        }
        if (msg.opType === OpType.DELETE) {
          setCertificates((certs) => [
            ...certs
              .filter((m) => m.id !== msg.element.id)
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              ),
          ]);
        }
      });
      // subscribe to provide services changes
      const provideServicesSubscription = DataStore.observe(
        ProvideService,
        (ps) => ps.provideServiceOwnerId.eq(userId)
      ).subscribe((msg) => {
        //console.log(msg.model, msg.opType, msg.element);
        if (msg.opType === OpType.INSERT) {
          setProvideServices((msgs) =>
            [...msgs, msg.element].sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
          );
        }
        if (msg.opType === OpType.UPDATE) {
          setProvideServices((msgs) => [
            ...msgs
              .map((existingMessage) =>
                existingMessage.id !== msg.element.id
                  ? existingMessage
                  : msg.element
              )
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              ),
          ]);
        }
        if (msg.opType === OpType.DELETE) {
          setProvideServices((msgs) => [
            ...msgs
              .filter((m) => m.id !== msg.element.id)
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              ),
          ]);
        }
      });
      // subscribe to Basic Info changes
      const basicInfoSubscription = DataStore.observe(BasicInfo, (bi) =>
        bi.basicInfoOwnerId.eq(userId)
      ).subscribe((msg) => {
        //console.log(msg.model, msg.opType, msg.element);
        if (msg.opType === OpType.INSERT || msg.opType === OpType.UPDATE) {
          removeSectionToFinish(RequiredProfileSections.basicInfo);
        }
        setBasicInfo(msg.element);
      });

      // subscribe to other certs changes
      const workHistorySubscription = DataStore.observe(WorkHistory, (wh) =>
        wh.workHistoryOwnerId.eq(userId)
      ).subscribe((msg) => {
        //console.log(msg.model, msg.opType, msg.element);
        if (msg.opType === OpType.INSERT) {
          removeSectionToFinish(RequiredProfileSections.workHistory);
          setWorkHistory((certs) =>
            [...certs, msg.element].sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
          );
        }
        if (msg.opType === OpType.UPDATE) {
          removeSectionToFinish(RequiredProfileSections.workHistory);
          setWorkHistory((certs) => [
            ...certs
              .map((existingCert) =>
                existingCert.id !== msg.element.id ? existingCert : msg.element
              )
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              ),
          ]);
        }
        if (msg.opType === OpType.DELETE) {
          setWorkHistory((prev) => {
            const updatedWorkHistoryList = [
              ...prev
                .filter((m) => m.id !== msg.element.id)
                .sort(
                  (a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                ),
            ];
            if (updatedWorkHistoryList.length > 0) {
              removeSectionToFinish(RequiredProfileSections.workHistory);
            }
            return updatedWorkHistoryList;
          });
        }
      });
      return () => {
        applicationSubscription.unsubscribe();
        otherCertsSubscription.unsubscribe();
        businessLicenseSubscription.unsubscribe();
        provideServicesSubscription.unsubscribe();
        basicInfoSubscription.unsubscribe();
        workHistorySubscription.unsubscribe();
        insuranceSubscription.unsubscribe();
      };
    }
  }, [userId, sectionsToFinish]);

  return {
    user,
    application,
    basicInfo,
    isApproved: application
      ? application.status === ServiceProviderApplicationStatus.APPROVED
      : false,
    businessLicense,
    sectionsToFinish,
    insurance,
    isFetching,
    workHistory,
    certificates,
    provideServices,
  };
}
