import React from "react";
import { useNavigate } from "react-router-dom";
import { Feed } from "semantic-ui-react";
import useMessage from "../hooks/use-message";
import ProfilePhoto from "./ProfilePhoto";
const JobMessage = ({ messageId }) => {
  const details = useMessage(messageId);
  const navigation = useNavigate();
  return (
    <Feed.Event>
      <Feed.Label>
        <ProfilePhoto user={details.owner} />
      </Feed.Label>
      <Feed.Content>
        <Feed.Summary>
          <Feed.User
            as={"a"}
            onClick={() => {
              navigation(`/profile/${details.owner?.id}`);
            }}
          >
            {[details.owner?.firstName, details.owner?.lastName].concat(" ")}
          </Feed.User>
          <Feed.Date>{details.message?.createdAt}</Feed.Date>
        </Feed.Summary>
        <Feed.Extra text>{details.message?.message}</Feed.Extra>
      </Feed.Content>
    </Feed.Event>
  );
};

export default JobMessage;
