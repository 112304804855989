import { DataStore } from "aws-amplify";
import { useEffect } from "react";
import { useContext, useState } from "react";
import { UserContext } from "../components/UserContext";
import { Ambassador } from "../models";

const useAmbassador = () => {
  const { user } = useContext(UserContext);
  const [data, setData] = useState<Ambassador | undefined>();
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    if (isFetching && user) {
      const fetchData = async () => {
        try {
          const response = await DataStore.query(Ambassador, (a) =>
            a.userId.eq(user.id)
          );
          if (response && response.length > 0) {
            setData(response[0]);
          }
        } catch (e) {}
        setIsFetching(false);
      };
      void fetchData();
    }
  }, [user, isFetching]);
  useEffect(() => {
    if (user) {
      //   //console.log("subscribe user");
      const subscription = DataStore.observeQuery(Ambassador, (a) =>
        a.userId.eq(user.id)
      ).subscribe((snapshot) => {
        const { items, isSynced } = snapshot;
        if (items && items.length > 0) {
          setData(items[0]);
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [user]);
  return {
    data,
    isFetching,
  };
};
export default useAmbassador;
