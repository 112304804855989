import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Label } from "semantic-ui-react";
import useUser from "../hooks/use-user";
import ProfilePhoto from "./ProfilePhoto";
import { UserContext } from "./UserContext";

const Avatar = ({ userId }) => {
  const navigation = useNavigate();
  const { user } = useUser(userId);
  return (
    <Label
      as={"a"}
      onClick={() => {
        navigation(`/profile/${user?.id}`);
      }}
      image
    >
      <ProfilePhoto user={user} />
      {user?.firstName}
    </Label>
  );
};

export default Avatar;
