import { DataStore } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { BasicInfo, User } from "../models";
import { Table, Label, Button } from "semantic-ui-react";
import Paper from "@mui/material/Paper";
import useOneSignalApi from "../hooks/use-one-signal";
const ApplicationRow = ({ application }) => {
  const [basicInfo, setBasicInfo] = useState();
  const [owner, setOwner] = useState();
  const oneSignalApi = useOneSignalApi();
  useEffect(() => {
    (async () => {
      if (application.serviceProviderApplicationBasicInfoId) {
        const fetchedInfo = await DataStore.query(
          BasicInfo,
          application.serviceProviderApplicationBasicInfoId
        );
        setBasicInfo(fetchedInfo);
      }
      if (application.serviceProviderApplicationOwnerId) {
        const fetchedInfo = await DataStore.query(
          User,
          application.serviceProviderApplicationOwnerId
        );
        setOwner(fetchedInfo);
      }
    })();
  }, [application]);
  const tagLifeTimeDrip = async () => {
    await oneSignalApi.registerUser(owner);
    await oneSignalApi.setTags(owner, { lifeTimeInProgress: "true" });
  };
  const tagIsServiceProvider = async () => {
    await oneSignalApi.registerUser(owner);
    await oneSignalApi.setTags(owner, { isServiceProvider: "true" });
  };
  return (
    <Table.Row key={application.id}>
      <Table.Cell align={"left"} component="th" scope="row">
        {application.status}
      </Table.Cell>
      {/* <Table.Cell>
        <Button
          onClick={() => {
            void tagLifeTimeDrip();
          }}
        >
          Tag lifetime drip
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Button
          onClick={() => {
            void tagIsServiceProvider();
          }}
        >
          Tag Is Service Provider
        </Button>
      </Table.Cell> */}
      <Table.Cell align="right">
        {new Date(application.createdAt).toDateString()}
      </Table.Cell>
      <Table.Cell align="right">
        {owner ? owner.firstName + " " + owner.lastName : "-"}
      </Table.Cell>
      <Table.Cell align="right">{owner ? owner.email : "-"}</Table.Cell>
      <Table.Cell align="right">{owner ? owner.phoneNumber : "-"}</Table.Cell>
      <Table.Cell align="right">
        {basicInfo && basicInfo.companyName ? basicInfo.companyName : "-"}
      </Table.Cell>
    </Table.Row>
  );
};

export default ApplicationRow;
