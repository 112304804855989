import { useEffect, useState } from "react";
import { Analytics, Auth, DataStore } from "aws-amplify";
import { ThemeProvider } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import branch from "branch-sdk";
import WebFont from "webfontloader";
import Dashboard from "./Dashboard";
import ApplicationPage from "./pages/Application";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import AmbassadorSignUpPage from "./pages/AmbassadorSignUp";
import { createTheme } from "@mui/material/styles";
import "semantic-ui-css/semantic.min.css";
import AuthRoute from "./components/AuthRoute";
import { useLocation } from "react-router-dom";
import Profile from "./pages/Profile";
import ShareJobPreview from "./pages/ShareJobPreview";
import JobDetails from "./pages/JobDetails";
import HeaderMenu from "./components/HeaderMenu";
import UserContextProvider, { UserContext } from "./components/UserContext";
import { useContext } from "react";
import AmbassadorTab from "./pages/AmbassadorTab";
import ServiceProviderOnboarding from "./pages/ServiceProviderOnboarding";
import ContractorTab from "./pages/ContractorTab";
import useOneSignalApi from "./hooks/use-one-signal";
import VideoPreview from "./pages/VideoPreview";
import PaymentProcessingRedirect from "./pages/PaymentProcessingRedirect";
import PaymentSuccess from "./pages/PaymentSuccess";
import ExternalPaymentSuccess from "./pages/ExternalPaymentSuccess";
branch.init("key_live_je1dTuIPVv4guvzfd1RMPiaatvanq9yq");
const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#e20107",
      dark: "#000",
      contrastText: "#F0F0F6",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
Analytics.disable();
const App = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { user } = useContext(UserContext);
  const [isSignedIn, setIsSignedIn] = useState(undefined);

  const logout = async () => {
    await DataStore.clear();
    await Auth.signOut();
    window.location.reload();
  };
  const [hasUpdatedOS, setHasUpdatedOS] = useState(false);
  const [isUpdatingOS, setIsUpdatingOS] = useState(false);
  const oneSignalApi = useOneSignalApi(user);
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Montserrat"],
      },
    });
  }, []);
  useEffect(() => {
    if (user !== undefined && !hasUpdatedOS && !isUpdatingOS) {
      const updateOneSignalData = async () => {
        await oneSignalApi.registerUser(user);
        setHasUpdatedOS(true);
      };
      setIsUpdatingOS(true);
      void updateOneSignalData();
    } else if (user) {
    }
  }, [
    user,
    hasUpdatedOS,
    setHasUpdatedOS,
    oneSignalApi,
    isUpdatingOS,
    setIsUpdatingOS,
  ]);
  useEffect(() => {
    if (user) {
      branch.setIdentity(user.id, (err, data) => {
        if (data) {
          console.log("personal link" + data.link);
          console.log("identity id" + data.identity_id);
        }
      });
      branch.data((err, data) => {
        console.log(JSON.stringify(data));
      });
    }
  }, [user]);

  return (
    <div>
      <HeaderMenu />
      <Routes>
        <Route
          path="/job/:id"
          element={
            <AuthRoute>
              <JobDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/application/pay"
          element={
            <AuthRoute isSubscribed={false}>
              <ApplicationPage price={49925} />
            </AuthRoute>
          }
        />
        <Route
          path="/application/payv2"
          element={
            <AuthRoute isSubscribed={false}>
              <ApplicationPage price={25000} />
            </AuthRoute>
          }
        />
        <Route
          path="/ambassador"
          element={
            <AuthRoute>
              <AmbassadorTab />
            </AuthRoute>
          }
        />
        <Route
          path="/contractor"
          element={
            <AuthRoute isSubscribed={true}>
              <ContractorTab />
            </AuthRoute>
          }
        />
        <Route
          path="/payment-processing/redirect"
          element={
            <AuthRoute isSubscribed={true}>
              <PaymentProcessingRedirect />
            </AuthRoute>
          }
        />
        <Route path="/payment-success" element={<ExternalPaymentSuccess />} />
        <Route path="/internal/payment-success" element={<PaymentSuccess />} />
        <Route
          path="/dashboard"
          element={
            <AuthRoute>
              <Dashboard />
            </AuthRoute>
          }
        />
        <Route
          path="/ambassador/signup"
          element={
            <AuthRoute>
              <AmbassadorSignUpPage />
            </AuthRoute>
          }
        />
        <Route
          path="/profile/:userId"
          element={
            <AuthRoute>
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="/"
          element={
            <AuthRoute>
              <HomePage />
            </AuthRoute>
          }
        />
        <Route path="/preview" element={<ShareJobPreview />} />
        <Route path="/mike" element={<VideoPreview />} />
        <Route path="/onboarding" element={<ServiceProviderOnboarding />} />
        <Route
          path="/login"
          element={
            user ? (
              <Navigate
                to={
                  location.state && location.state.redirect
                    ? location.state.redirect
                    : "/"
                }
              />
            ) : (
              <LoginPage
                loginSuccess={() => {
                  setIsSignedIn(true);
                }}
              />
            )
          }
        />
      </Routes>
    </div>
  );
};

const AppContainer = () => {
  return (
    <Router>
      <UserContextProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </UserContextProvider>
    </Router>
  );
};

export default AppContainer;
