import { DataStore, Storage } from "aws-amplify";
import { useEffect } from "react";
import {
  Button,
  Table,
  Modal,
  Form,
  FormField,
  Input,
} from "semantic-ui-react";
import { useImmer } from "use-immer";
import { displayUserFullName } from "../constants";
import { Ambassador, User } from "../models";

const AmbasssadorTableRow = ({ ambassador }) => {
  const [rowData, setRowData] = useImmer({
    user: undefined,
    OAPLink: undefined,
    W9Link: undefined,
    denyMessage: "",
    showDenyModal: false,
  });
  const status = ambassador.isApproved
    ? "Approved"
    : ambassador.isDenied
    ? "Denied"
    : "In Review";
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await DataStore.query(User, ambassador.userId);
        setRowData((draft) => {
          draft.user = user;
        });
      } catch (e) {}
    };
    const fetchLinks = async () => {
      try {
        const oapLink = await Storage.get(ambassador.OAP.key);
        const w9Link = await Storage.get(ambassador.w9.key);
        //console.log(oapLink);
        setRowData((draft) => {
          draft.OAPLink = oapLink;
          draft.W9Link = w9Link;
        });
      } catch (e) {}
    };
    if (ambassador && ambassador.userId) {
      void fetchUser();
    }
    if (ambassador && ambassador.OAP && ambassador.w9) {
      void fetchLinks();
    }
  }, [ambassador]);
  const approve = async () => {
    try {
      await DataStore.save(
        Ambassador.copyOf(ambassador, (updated) => {
          updated.approvedAt = new Date().toISOString();
          updated.isApproved = true;
        })
      );
      setRowData((draft) => {
        draft.denyMessage = "";
        draft.showDenyModal = false;
      });
    } catch (e) {}
  };
  const deny = async () => {
    try {
      await DataStore.save(
        Ambassador.copyOf(ambassador, (updated) => {
          updated.deniedAt = new Date().toISOString();
          updated.deniedReason = rowData.denyMessage;
          updated.isDenied = true;
        })
      );
      setRowData((draft) => {
        draft.denyMessage = "";
        draft.showDenyModal = false;
      });
    } catch (e) {}
  };
  return (
    <Table.Row>
      <Table.Cell>
        {rowData.user ? displayUserFullName(rowData.user) : "-"}
      </Table.Cell>
      <Table.Cell>
        {rowData.OAPLink ? (
          <a href={rowData.OAPLink}>View OAP Doc</a>
        ) : (
          <div>no link</div>
        )}
      </Table.Cell>
      <Table.Cell>
        {rowData.W9Link ? (
          <a href={rowData.W9Link}>View W9</a>
        ) : (
          <div>no link</div>
        )}
      </Table.Cell>
      <Table.Cell>
        {ambassador.isApproved
          ? "Approved"
          : ambassador.isDenied
          ? "Denied"
          : "In Review"}
      </Table.Cell>
      <Table.Cell>
        {ambassador.isDenied || ambassador.isApproved ? (
          <div>-</div>
        ) : (
          <div>
            <Button onClick={approve}>Approve</Button>
            <Button
              onClick={() => {
                setRowData((draft) => {
                  draft.showDenyModal = true;
                });
              }}
            >
              Deny
            </Button>
          </div>
        )}
      </Table.Cell>
      <Modal
        open={rowData.showDenyModal}
        onClose={() => {
          setRowData((draft) => {
            draft.showDenyModal = false;
          });
        }}
      >
        <Modal.Header>
          Deny ambassador {displayUserFullName(rowData.user)}
        </Modal.Header>
        <Modal.Content>
          <Input
            fluid
            placeholder={"Reason for denying"}
            value={rowData.denyMessage}
            onChange={(e) => {
              setRowData((draft) => {
                draft.denyMessage = e.target.value;
              });
            }}
          ></Input>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setRowData((draft) => {
                draft.showDenyModal = false;
              });
            }}
          >
            Cancel
          </Button>
          <Button color={"red"} onClick={deny}>
            Deny
          </Button>
        </Modal.Actions>
      </Modal>
    </Table.Row>
  );
};

export default AmbasssadorTableRow;
