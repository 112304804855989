import {
  Container,
  Divider,
  Form,
  Message,
  List,
  Header,
  Button,
} from "semantic-ui-react";
import ReactPlayer from "react-player";

import { SubscriptionPayment, SubscriptionType, User } from "../models";
import { pageStyle } from "../constants";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import branch from "branch-sdk";
const VideoPreview = () => {
  const [params, setSearchParams] = useSearchParams();

  const videoKey = decodeURIComponent(params.get("videoKey"));
  const service = decodeURIComponent(params.get("service"));
  const jobId = decodeURIComponent(params.get("jobId"));
  const description = decodeURIComponent(params.get("description"));
  const videoUri = "https://content.bidmyvid.com/email-videos/mike.mov";

  return (
    <Container style={pageStyle.mainContainer}>
      <Header as={"h1"}>Thank you for clicking the link and greetings!</Header>
      <p>
        Please watch the video below. Apologies for recording this while being
        sick!
      </p>
      <Divider></Divider>
      {videoUri != "" && (
        <>
          <ReactPlayer
            url={videoUri}
            controls
            width={"100%"}
            height={window.innerHeight}
          />
        </>
      )}

      <List>
        <List.Item>
          <List.Header>{"Keep moving forward"}</List.Header>
          <List.Content>{"Mikey v"}</List.Content>
        </List.Item>
      </List>
      <div>
        <a href={"https://app.bidmyvid.com/application/pay"}>
          Sign up and take advantage of lifetime offer!
        </a>
      </div>
      <div>
        <a href={"https://bidmyvid.com"}>Check out our website</a>
      </div>
      <div style={{ height: 100 }}></div>
    </Container>
  );
};

export default VideoPreview;
