import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { TextField, Snackbar, Alert, Stack, Paper } from "@mui/material";
import { Container, Button, Divider, Header, Grid } from "semantic-ui-react";
import LoadingButton from "@mui/lab/LoadingButton";

import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import SignupForm from "../components/SignupForm";
import { pageStyle } from "../constants";
import ForgotPassword from "../components/ForgotPassword";
export const LoginType = {
  Default: 0,
  Login: 1,
  SignUp: 2,
  ForgotPassword: 3,
};
const LoginPage = ({ loginSuccess }) => {
  const [loginType, setLoginType] = useState(LoginType.SignUp);
  return (
    <Container style={pageStyle.mainContainer}>
      {loginType === LoginType.Default && (
        <Grid textAlign="center" stretched columns={1}>
          <Grid.Column>
            <Header as={"h1"}>Welcome to BidMyVid!</Header>
            <Button.Group>
              <Button
                color={"red"}
                onClick={() => {
                  setLoginType(LoginType.SignUp);
                }}
                variant={"contained"}
              >
                Create account
              </Button>
              <Button.Or />
              <Button
                onClick={() => {
                  setLoginType(LoginType.Login);
                }}
              >
                Login
              </Button>
            </Button.Group>
            <Divider hidden />
            <Button basic>
              <a href="https://bidmyvid.app.link/jzt3fPMkqub">Download App</a>
            </Button>
          </Grid.Column>
        </Grid>
      )}
      {loginType === LoginType.Login && (
        <LoginForm
          goToSignUp={() => {
            setLoginType(LoginType.SignUp);
          }}
          goToForgotPassword={() => {
            setLoginType(LoginType.ForgotPassword);
          }}
          loginSuccess={loginSuccess}
        />
      )}
      {loginType === LoginType.SignUp && (
        <SignupForm
          goToLogin={() => {
            setLoginType(LoginType.Login);
          }}
        />
      )}
      {loginType === LoginType.ForgotPassword && (
        <ForgotPassword
          goToLogin={() => {
            setLoginType(LoginType.Login);
          }}
          resetSuccess={() => {
            loginSuccess();
          }}
        />
      )}
    </Container>
  );
};
export default LoginPage;
