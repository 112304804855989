import { useState, useEffect } from "react";
import { DataStore, Logger } from "aws-amplify";
import { Message } from "../models";
import { OpType } from "@aws-amplify/datastore";
const logger = new Logger("useJobMessages");
export default function useJobMessages(jobId) {
  const [messages, setMessages] = useState([]);
  const [initialFetch, setInitialFetch] = useState(true);
  const refreshMessages = () => {
    setInitialFetch(true);
  };
  useEffect(() => {
    if (jobId && initialFetch) {
      (async () => {
        try {
          const messages = await DataStore.query(Message, (m) =>
            m.messageJobId.eq(jobId)
          );
          setMessages(
            messages.sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
          );
          setInitialFetch(false);
        } catch (e) {
          logger.warn("Failed to fetch messages for job" + jobId, e);
        }
      })();
    }
  }, [jobId, initialFetch]);
  useEffect(() => {
    const subscription = DataStore.observe(Message, (m) =>
      m.messageJobId.eq(jobId)
    ).subscribe((msg) => {
      console.log(msg.model, msg.opType, msg.element);
      if (msg.opType === OpType.INSERT) {
        setMessages((msgs) =>
          [...msgs, msg.element].sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          )
        );
      }
      if (msg.opType === OpType.UPDATE) {
        setMessages((msgs) => [
          ...msgs
            .map((existingMessage) =>
              existingMessage.id !== msg.element.id
                ? existingMessage
                : msg.element
            )
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            ),
        ]);
      }
      if (msg.opType === OpType.DELETE) {
        setMessages((msgs) => [
          ...msgs
            .filter((m) => m.id !== msg.element.id)
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            ),
        ]);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return {
    messages,
    isFetchingMessages: initialFetch,
    refreshMessages,
  };
}
