import { useState, useEffect } from "react";
import { DataStore, Logger } from "aws-amplify";
import { Bid, Message, User } from "../models";
import useServiceProvider from "./use-service-provider";
const logger = new Logger("useMessage");
export default function useMessage(id) {
  const [message, setMessage] = useState();
  const [owner, setOwner] = useState();
  const serviceProvider = useServiceProvider(owner ? owner.id : undefined);
  const [initialFetch, setInitialFetch] = useState(true);

  useEffect(() => {
    if (id && initialFetch) {
      (async () => {
        try {
          const fetchedMessage = await DataStore.query(Message, id);
          setMessage(fetchedMessage);
          if (!fetchedMessage) {
            throw new Error("Failed to fetch message" + id);
          }
          const fetchedOwner = await DataStore.query(
            User,
            fetchedMessage.messageOwnerId
          );
          setOwner(fetchedOwner);
        } catch (e) {
          logger.warn("Failed to fetch bid for " + id, e);
        }
        setInitialFetch(false);
      })();
    }
  }, [id, initialFetch]);
  return {
    message,
    owner,
    serviceProvider,
    isFetching: initialFetch || serviceProvider.isFetching,
  };
}
