import { DataStore } from "aws-amplify";
import { useState } from "react";
import { User } from "../models";

const useOneSignalApi = () => {
  const [hasSetData, setHasSetData] = useState(false);
  const [isRegisteringUser, setisRegisteringUser] = useState(false);
  const apiUrl = "https://onesignal.com/api/v1/players";
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const setEmail = async (user) => {
    const payload = JSON.stringify({
      app_id: "c09b87df-ecd2-43ce-a802-d485dbb184b9",
      device_type: 11,
      external_user_id: user.id,
      identifier: user.email,
      language: "en",
      tags: {
        first_name: user.firstName ?? "",
        last_name: user.lastName ?? "",
        isEmployee: user.email.includes("bidmyvid.com") ? "true" : "false",
      },
      country: "US",
    });
    const response = await fetch("https://onesignal.com/api/v1/players", {
      ...options,
      body: payload,
    });

    const data = await response.json();

    await DataStore.save(
      User.copyOf(user, (updated) => {
        updated.oneSignalEmailId = data.id;
      })
    );
  };
  const setTags = async (user, tags) => {
    try {
      const payload = {
        method: "PUT",
        headers: {
          accept: "text/plain",
          Authorization:
            "Basic MDcwNWY4Y2EtYWNlMS00ODNlLTkyMzktYWNhZjZlNmU5OThi",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ tags }),
      };
      const response = await fetch(
        `https://onesignal.com/api/v1/apps/c09b87df-ecd2-43ce-a802-d485dbb184b9/users/${user.id}`,
        payload
      );
      const data = await response.json();
      //console.log("Response from setting tags", JSON.stringify(data));
    } catch (e) {
      //console.log("Issue setting tags for user".e);
    }
  };
  const setSMS = async (user) => {
    const payload = JSON.stringify({
      app_id: "c09b87df-ecd2-43ce-a802-d485dbb184b9",
      device_type: 14,
      external_user_id: user.id,
      identifier: user.phoneNumber,
      language: "en",
      tags: {
        first_name: user.firstName ?? "",
        last_name: user.lastName ?? "",
        isEmployee: user.email.includes("bidmyvid.com") ? "true" : "false",
      },
      country: "US",
    });
    const response = await fetch("https://onesignal.com/api/v1/players", {
      ...options,
      body: payload,
    });
    const data = await response.json();

    await DataStore.save(
      User.copyOf(user, (updated) => {
        updated.oneSignalPhoneId = data.id;
      })
    );
  };
  const registerUser = async (user) => {
    await setEmail(user);
    await setSMS(user);
  };
  const getOneSignalUser = async (user) => {
    const payload = {
      method: "GET",
      headers: {
        accept: "text/plain",
        Authorization: "Basic MDcwNWY4Y2EtYWNlMS00ODNlLTkyMzktYWNhZjZlNmU5OThi",
        "Content-Type": "application/json; charset=utf-8",
      },
    };

    const response = await fetch(
      `https://onesignal.com/api/v1/players/${user.id}?app_id=c09b87df-ecd2-43ce-a802-d485dbb184b9`,
      payload
    );
    const data = await response.json();
    //console.log(JSON.stringify(data));
  };
  return {
    registerUser,
    getOneSignalUser,
    setTags,
  };
};

export default useOneSignalApi;
