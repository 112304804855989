import { Typography } from "@mui/material";
import { API } from "aws-amplify";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useImmer } from "use-immer";
import Page from "../components/Page";
const ExternalPaymentSuccess = () => {
  return (
    <Page>
      <div>
        <Typography variant={"h5"} sx={{ fontFamily: "Montserrat" }}>
          Thank you for your payment!
        </Typography>
      </div>
    </Page>
  );
};

export default ExternalPaymentSuccess;
