// @ts-nocheck
import { DataStore, Logger } from "aws-amplify";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Container,
  Label,
  Loader,
  List,
  Checkbox,
  Divider,
  Message,
} from "semantic-ui-react";
import { useImmer } from "use-immer";
import {
  displayUserFullName,
  pageStyle,
  setRevenueCatAttributes,
} from "../constants";
import useProvidedServices from "../hooks/use-provided-services";
import useQuery from "../hooks/use-query";
import {
  Ambassador,
  JobService,
  ProvideService,
  ServiceProviderApplication,
  ServiceProviderApplicationStatus,
  User,
} from "../models";
import { UserContext } from "./UserContext";
import "./ContractorApplicationWalkthrough.css";
import ApplicationPayment from "./ApplicationPayment";
import useOneSignalApi from "../hooks/use-one-signal";
import { Box, Typography, Button } from "@mui/material";
import Page from "./Page";
const logger = new Logger("contractor-application");
const ContractorApplicationWalkthrough = ({ price }) => {
  const { user } = useContext(UserContext);
  const query = useQuery();
  const navigation = useNavigate();
  const oneSignalApi = useOneSignalApi();
  const { providedServices, isFetchingProvidedServices } =
    useProvidedServices();
  const ambRefId = query.get("ambRefId");
  const [state, setState] = useImmer({
    isIntroduction: false,
    isInitializing: true,
    isSelectingServices: false,
    isFetchingServices: false,
    isPaying: false,
    isProcessingPayment: false,
    isSavingServices: false,
    ambassador: undefined,
    selectedServices: [],
    services: undefined,

    application: undefined,
  });
  useEffect(() => {
    if (user && !user.ambassRefId && ambRefId) {
      (async () => {
        //console.log(JSON.stringify(user));
        //console.log(ambRefId);
        await DataStore.save(
          User.copyOf(user, (updated) => {
            updated.ambassRefId = ambRefId;
          })
        );
      })();
    }
  }, [user, ambRefId]);
  useEffect(() => {
    if (user) {
      const fetchAmbassadorUser = async (userId) => {
        const userInfo = await DataStore.query(User, userId);
        if (userInfo) {
          setState((draft) => {
            draft.ambassador.user = userInfo;
          });
        }
        setState((draft) => {
          draft.isInitializing = false;
        });
      };
      const fetchAmbassadorInfo = async () => {
        const ambassadorData = await DataStore.query(Ambassador, ambRefId);

        if (ambassadorData) {
          setState((draft) => {
            draft.ambassador = draft.ambassador = { data: ambassadorData };
          });
          void fetchAmbassadorUser(ambassadorData.userId);
        } else {
          setState((draft) => {
            draft.isInitializing = false;
          });
        }
      };
      const getServices = async () => {
        setState((draft) => {
          draft.isFetchingServices = true;
        });
        const items = await DataStore.query(JobService);

        if (items) {
          // // logger.info('Job services - ' + JSON.stringify(items));
          setState((draft) => {
            draft.services = items;
            draft.isFetchingServices = false;
          });
        }
      };
      const fetchApplication = async () => {
        const response = await DataStore.query(
          ServiceProviderApplication,
          (sp) => sp.serviceProviderApplicationOwnerId.eq(user.id)
        );
        await oneSignalApi.setTags(user, {
          lifeTimeInProgress: "true",
        });
        if (response.length > 0) {
          setState((draft) => {
            draft.application = response[0];
            draft.isIntroduction = true;
          });
        } else {
          setState((draft) => {
            draft.isIntroduction = true;
          });
        }
        // if (ambRefId) {
        //   void fetchAmbassadorInfo();
        // } else {
        setState((draft) => {
          draft.isInitializing = false;
        });
        // }
      };
      if (state.isInitializing) {
        void getServices();
        void fetchApplication();
      }
    }
  }, [user, ambRefId, state.isInitializing, setState]);
  useEffect(() => {
    if (!isFetchingProvidedServices && state.services !== undefined) {
      setState((draft) => {
        draft.selectedServices = providedServices.map(
          (item) => item.provideServiceServiceId
        );
      });
    }
  }, [providedServices, isFetchingProvidedServices, setState, state.services]);
  const saveProvidedServices = async () => {
    // TODO: delete any services not selected any more
    let didUpdate = false;
    setState((draft) => {
      draft.isSavingServices = true;
    });
    try {
      if (providedServices) {
        // remove services they aren't providing or unselected

        await Promise.all(
          providedServices.map(async (provideService) => {
            if (
              !state.selectedServices.includes(
                provideService.provideServiceServiceId
              )
            ) {
              return await DataStore.delete(ProvideService, provideService.id);
            }
          })
        );
        // add service providers

        await Promise.all(
          state.selectedServices.map(async (id) => {
            if (
              providedServices.findIndex(
                (ps) => ps.provideServiceServiceId === id
              ) === -1
            ) {
              if (state.application) {
                return await DataStore.save(
                  new ProvideService({
                    provideServiceApplicationId: state.application.id,
                    provideServiceOwnerId: user.id,
                    provideServiceServiceId: id,
                  })
                );
              }
              // return await DataStore.delete(ProvideService, provideService.id);
            }
          })
        );
        //console.log("Did we see this?");
      }
    } catch (e) {
      logger.warn("Issue saving provide services", e);
    }
    // navigation.goBack();
    setState((draft) => {
      draft.isSavingServices = false;
      //console.log("Is it failing here");

      if (draft.selectedServices && draft.selectedServices.length > 0) {
        //console.log(JSON.stringify(draft.selectedServices));
        draft.isPaying = true;
        draft.isSelectingServices = false;
        //console.log("Inside");
      } else {
        draft.isPaying = true;
        //console.log("outside");
        draft.isSelectingServices = false;
      }
    });
  };
  const isSelectedService = (serviceId) => {
    const isSelected =
      state.selectedServices.findIndex((id) => id === serviceId) !== -1;
    return isSelected;
  };
  const selectService = (id) => {
    if (state.selectedServices.findIndex((item) => item === id) !== -1) {
      // remove service
      setState((draft) => {
        draft.selectedServices = draft.selectedServices.filter(
          (item) => item !== id
        );
      });
    } else {
      setState((draft) => {
        draft.selectedServices = [...draft.selectedServices, id];
      });
    }
  };
  const createApplication = async () => {
    if (user && !state.application) {
      const newApplication = await DataStore.save(
        new ServiceProviderApplication({
          serviceProviderApplicationOwnerId: user.id,
          status: ServiceProviderApplicationStatus.IN_PROGRESS,
        })
      );
      await oneSignalApi.setTags(user, {
        lifeTimeInProgress: "true",
      });
      setState((draft) => {
        draft.application = newApplication;
        draft.isIntroduction = false;
        draft.isSelectingServices = true;
      });
    } else {
      await oneSignalApi.setTags(user, {
        lifeTimeInProgress: "true",
      });
      setState((draft) => {
        draft.isIntroduction = false;
        draft.isSelectingServices = true;
      });
    }
  };
  const approveApplication = async () => {
    if (state.application) {
      logger.info("Have an application!");
      if (
        state.application.status !== ServiceProviderApplicationStatus.APPROVED
      ) {
        try {
          await DataStore.save(
            ServiceProviderApplication.copyOf(state.application, (updated) => {
              updated.status = ServiceProviderApplicationStatus.APPROVED;
            })
          );
          await oneSignalApi.setTags(user, {
            isServiceProvider: "true",
            lifeTimeInProgress: "",
          });
        } catch (e) {
          logger.error("Issue updating application to approved state", e);
        }
      } else {
        await oneSignalApi.setTags(user, {
          isServiceProvider: "true",
          lifeTimeInProgress: "",
        });
      }
      navigation("/onboarding", { replace: true });
    }
  };

  return (
    <Page>
      <Box sx={{ textAlign: "center" }}>
        <img src={"/bmv_icon.png"} height="120px" width="120px"></img>
        {state.isInitializing && <Loader active>Loading...</Loader>}
        {!state.isInitializing && user && state.isIntroduction && (
          <Box>
            <Typography variant={"h5"} fontFamily={"Montserrat"}>
              Become powered by BidMyVid!
            </Typography>
            <Box sx={{ marginY: "2em" }}>
              <img
                src={"/BidMyVidPlatinumPreview.jpg"}
                className="service-preview-img"
              ></img>
            </Box>
            <Box
              sx={{
                marginY: "2em",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  void createApplication();
                }}
              >
                <Typography fontFamily={"Montserrat"}>Get Started</Typography>
              </Button>
            </Box>
          </Box>
        )}
        {state.isSelectingServices && state.services !== undefined && (
          <div>
            <Message info>
              <Message.Header>
                <Typography fontFamily={"Montserrat"} fontWeight={"700"}>
                  Don't see your service? No worries!
                </Typography>
              </Message.Header>
              <Message.Content>
                <Typography fontFamily={"Montserrat"}>
                  We are adding services every day! Select General Service for
                  now and email addme@bidmyvid.com the service you'd like to see
                  added! We will be in touch ASAP to gather more details in
                  order to support you.
                </Typography>
              </Message.Content>
            </Message>
            <Typography variant="h6" fontFamily={"Montserrat"}>
              Choose the services you'd like to provide
            </Typography>
            <Divider hidden />
            <List>
              {state.services.map((service) => {
                return (
                  <List.Item>
                    <List.Content className="service-list-item">
                      <List.Description>
                        <Typography fontFamily={"Montserrat"}>
                          <Checkbox
                            checked={isSelectedService(service.id)}
                            onChange={(e) => {
                              selectService(service.id);
                            }}
                            label={service.name}
                          />
                        </Typography>
                      </List.Description>
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
            <Button
              variant="contained"
              onClick={() => {
                void saveProvidedServices();
              }}
            >
              <Typography fontFamily={"Montserrat"}>Next</Typography>
            </Button>
          </div>
        )}
        {state.isPaying && (
          <ApplicationPayment
            price={price}
            processingPayment={() => {
              setState((draft) => {
                draft.isProcessingPayment = true;
              });
            }}
            onComplete={() => {
              setState((draft) => {
                draft.isProcessingPayment = false;
              });
              void approveApplication();
            }}
            onFailedPayment={() => {
              setState((draft) => {
                draft.isProcessingPayment = false;
              });
            }}
          />
        )}
      </Box>
    </Page>
  );
};
export default ContractorApplicationWalkthrough;
