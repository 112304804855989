import { OpType } from "@aws-amplify/datastore";
import { DataStore } from "aws-amplify";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { Ambassador } from "../models";

const useManageAmbassadors = () => {
  const [data, setData] = useImmer({
    ambassadors: [],
    isFetchingData: true,
    failedToApproveMessage: "",
    isApprovingAmbassador: "",
    isDenyingAmbassador: "",
    failedToDenyMessage: "",
  });
  useEffect(() => {
    if (data.isFetchingData && data.ambassadors.length === 0) {
      const fetchAllAmbassadors = async () => {
        try {
          const response = await DataStore.query(Ambassador);
          setData((draft) => {
            draft.ambassadors = response;
            draft.isFetchingData = false;
          });
        } catch (e) {
          setData((draft) => {
            draft.isFetchingData = false;
          });
        }
      };
      void fetchAllAmbassadors();
    }
  }, [data.isFetchingData, data.ambassadors]);
  useEffect(() => {
    if (!data.isFetchingData) {
      //console.log("subscribe user");
      const subscription = DataStore.observe(Ambassador).subscribe((msg) => {
        //console.log(msg.model, msg.opType, msg.element);
        if (msg.opType === OpType.UPDATE) {
          const index = data.ambassadors.findIndex(
            (item) => item.id === msg.element.id
          );
          setData((draft) => {
            draft.ambassadors[index] = msg.element;
          });
        } else if (msg.opType === OpType.INSERT) {
          setData((draft) => {
            draft.ambassadors.push(msg.element);
          });
        }
        // see if it already exists, replace
        // if it does not exist, add
        // if it exists and needs to be removed
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [data.isFetchingData]);
  const approveAmbassador = async (ambassador) => {
    try {
      setData((draft) => {
        draft.isApprovingAmbassador = ambassador.id;
      });
      await DataStore.save(
        Ambassador.copyOf(ambassador, (updated) => {
          updated.isApproved = true;
          updated.approvedAt = new Date().toISOString();
        })
      );
      setData((draft) => {
        draft.isApprovingAmbassador = "";
      });
    } catch (e) {
      setData((draft) => {
        draft.isApprovingAmbassador = "";
        draft.failedToApproveMessage = e;
      });
    }
  };
  const denyAmbassador = async (ambassador, message) => {
    try {
      setData((draft) => {
        draft.isApprovingAmbassador = ambassador.id;
      });
      await DataStore.save(
        Ambassador.copyOf(ambassador, (updated) => {
          updated.isApproved = true;
          updated.approvedAt = new Date().toISOString();
        })
      );
      setData((draft) => {
        draft.isApprovingAmbassador = "";
      });
    } catch (e) {
      setData((draft) => {
        draft.isApprovingAmbassador = "";
        draft.failedToApproveMessage = e;
      });
    }
  };
  const clearFailedToApproveMessage = () => {
    setData((draft) => {
      draft.failedToApproveMessage = "";
    });
  };
  const clearFailedToDenyMessage = () => {
    setData((draft) => {
      draft.failedToDenyMessagen = "";
    });
  };
  return {
    ambassadors: data.ambassadors,
    isFetchingAmbassadors: data.isFetchingData,
    approveAmbassador,
    denyAmbassador,
    isDenying: data.isDenyingAmbassador !== "",
    isApproving: data.isApprovingAmbassador !== "",
    failedToApproveMessage: data.failedToApproveMessage,
    clearFailedToApproveMessage,
    clearFailedToDenyMessage,
  };
};

export default useManageAmbassadors;
