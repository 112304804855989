import { DataStore } from "aws-amplify";
import { useContext } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import { UserContext } from "../components/UserContext";
import { getEnvUrl, pageStyle } from "../constants";
import { Ambassador } from "../models";
import branch from "branch-sdk";
import Page from "../components/Page";
import { Button, Divider, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const AmbassadorTab = (props) => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const [state, setState] = useImmer({
    isInitializing: true,
    link: "",
    ambassador: undefined,
  });
  useEffect(() => {
    const fetchData = async () => {
      const response = await DataStore.query(Ambassador, (amb) =>
        amb.userId.eq(user.id)
      );
      if (response && response.length > 0) {
        const data = response[0];
        branch.link(
          {
            feature: "ambassador link",
            channel: "web",
            data: {
              $fallback_url: `https://app.bidmyvid.com/application/pay?ambRefId=${data.id}`,
              $og_description: "Get lifetime access to platinum membership!",
              $og_image_url:
                "https://content.bidmyvid.com/logos/bmv-platinum.jpg",
              $og_title: `BidMyVid Platinum`,
              $ios_url: `https://app.bidmyvid.com/application/pay?ambRefId=${data.id}`,
              // $referenceUrl_android: `bidmyvid://service/profile/${selectedUser.id}`,
              $desktop_url: `https://app.bidmyvid.com/application/pay?ambRefId=${data.id}`,
              $mobile_web_only: true,
              $web_only: true,
              $canonical_url: `https://app.bidmyvid.com`,
            },
          },
          async (err, link) => {
            if (link) {
              setState((draft) => {
                draft.link = link;
              });
            }
          }
        );
        setState((draft) => {
          draft.isInitializing = false;
          draft.ambassador = data;
        });
      }
    };
    if (user && state.isInitializing) {
      void fetchData();
    }
  }, [user, state.isInitializing]);
  return (
    <Page>
      <Typography variant={"h3"} sx={{ fontFamily: "Montserrat" }}>
        My Ambassador
      </Typography>
      <Button
        sx={{ fontFamily: "Montserrat", fontWeight: "700" }}
        variant={"outlined"}
        startIcon={<ContentCopyIcon />}
        onClick={() => {
          const copyLink = async () => {
            //console.log("copy link function");

            await navigator.clipboard.writeText(state.link);
          };
          void copyLink();
        }}
      >
        Affiliate Link
      </Button>
      <Typography
        variant={"h5"}
        sx={{ fontFamily: "Montserrat", marginTop: 5 }}
      >
        Resources...(Coming soon)
      </Typography>
      <Typography
        variant={"h5"}
        sx={{ fontFamily: "Montserrat", marginTop: 5 }}
      >
        Dashboard
      </Typography>
      <Divider light />
      <Typography
        variant={"h6"}
        sx={{ fontFamily: "Montserrat", marginTop: 5 }}
      >
        Total Earnings
      </Typography>
      <Typography
        variant={"body1"}
        sx={{ fontFamily: "Montserrat", marginTop: 2 }}
      >
        Not enough data.
      </Typography>
    </Page>
  );
};

export default AmbassadorTab;
