import React, { useState, useEffect } from "react";
import {
  Stack,
  TextField,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import { Form, Button, Container, Header } from "semantic-ui-react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useImmer } from "use-immer";
import { Colors, pageStyle } from "../constants";
import { Auth } from "aws-amplify";
const MFAForm = ({ state, updateState, confirmAccount }) => {
  return (
    <Container style={pageStyle.mainContainer}>
      <Header as={"h1"}>Verify Account</Header>
      <p>code sent to {state.codeSentTo}</p>
      <Form>
        <Form.Input
          label={"Code"}
          onChange={(e) => {
            updateState((draft) => {
              draft.mfaCode = e.target.value;
            });
          }}
          value={state.mfaCode}
        ></Form.Input>
        <Form.Button
          loading={state.isSubmittingMfa}
          color={"red"}
          onClick={() => {
            void confirmAccount();
          }}
        >
          Submit
        </Form.Button>
      </Form>
    </Container>
  );
};
export default MFAForm;
