import { useState } from "react";
import { User } from "../models";
import { DataStore, Auth, Hub } from "aws-amplify";
import { useEffect } from "react";
import _ from "lodash";
const useUser = (id) => {
  const [user, setUser] = useState();
  const [isFetchingUser, setIsFetchingUser] = useState(true);

  useEffect(() => {
    if (id) {
      return;
    }
    const authListener = Hub.listen("auth", (hubData) => {
      const { event } = hubData.payload;
      //console.log(event);

      if (event == "signIn") {
        setIsFetchingUser(true);
      }
      if (event == "signOut") {
        setUser(undefined);
      }
    });
    return () => {
      authListener();
    };
  }, [id]);
  useEffect(() => {
    ////console.log("is logged in");
    if (!isFetchingUser) {
      return;
    }
    if (id === undefined) {
      (async () => {
        try {
          const userInfo = await Auth.currentAuthenticatedUser();
          //console.log("Fetched current authenticated user");
          ////console.log("What is this: " + JSON.stringify(userInfo));
          const fetchedUserSub = DataStore.observeQuery(User, (u) =>
            u.cognitoId.eq(_.get(userInfo, "username"))
          ).subscribe(({ items }) => {
            setUser(items[0]);
            setIsFetchingUser(false);
          });
          return () => {
            fetchedUserSub.unsubscribe();
          };
          ////console.log("Fetched user: " + JSON.stringify(fetchedUser));
        } catch (e) {
          //console.log("Tried fetching user");
          setUser(undefined);
          setIsFetchingUser(false);
        }
        
      })();
    } else if (id !== undefined) {
      (async () => {
        const fetchedUser = await DataStore.query(User, id);
        ////console.log("Fetched user: " + JSON.stringify(fetchedUser));
        if (fetchedUser) {
          setUser(fetchedUser);
        }
        setIsFetchingUser(false);
      })();
    }
  }, [id, user, isFetchingUser]);
  useEffect(() => {
    if (user) {
      ////console.log("subscribe user");
      const subscription = DataStore.observe(User, user.id).subscribe((msg) => {
        ////console.log(msg.model, msg.opType, msg.element);
        setUser(msg.element);
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [user]);
  return { user, isFetchingUser };
};
export default useUser;
