export const Colors = {
  PRIMARY: "#e20107",
  BACKGROUND: "#F0F0F6",
  LIGHT_GRAY: "#989898",
  IN_PROGRESS: "#EF7900",
  STATUS_COMPLETED: "#00B331",
  STATUS_NOT_STARTED: "#E00E00",
};

export const pageStyle = {
  mainContainer: {
    marginTop: "3em",
    marginBottom: "3em",
  },
};
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const displayUserFullName = (user) => {
  if (!user) {
    return "";
  }
  return [user.firstName, user.lastName].join(" ");
};

export const setRevenueCatAttributes = async (user) => {
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer appl_JOYliAeCiReOcmqnBJrdaLwHWGi",
    },
    body: JSON.stringify({
      attributes: {
        $email: { value: user.email },
        $displayName: { value: displayUserFullName(user) },
      },
    }),
  };

  const revenueCatRequestUrl = `https://api.revenuecat.com/v1/subscribers/${user.id}/attributes`;
  try {
    const revenueCatPendingResponse = await fetch(
      revenueCatRequestUrl,
      options
    );
    const revenueCatResponse = await revenueCatPendingResponse.json();

    if (revenueCatPendingResponse.status >= 400) {
      //console.log("RevenueCatError: ", revenueCatPendingResponse);
    }

    //console.log("RevenueCatResponse Set Attributes: ", revenueCatResponse);
    return revenueCatResponse;
  } catch (e) {
    //console.log("RevenueCatError: ", e);
    return {};
  }
};

export const registerUserWithRevenueCat = async (user) => {
  const revenueCatRequestHeader = {
    method: "GET",
    headers: {
      accept: "application/json",
      "X-Platform": "ios",
      "Content-Type": "application/json",
      Authorization: "Bearer appl_JOYliAeCiReOcmqnBJrdaLwHWGi",
    },
  };

  const revenueCatRequestUrl =
    "https://api.revenuecat.com/v1/subscribers/" + user.id;
  //console.log("RevenueCatUrl: " + revenueCatRequestUrl);

  try {
    const revenueCatPendingResponse = await fetch(
      revenueCatRequestUrl,
      revenueCatRequestHeader
    );
    const revenueCatResponse = await revenueCatPendingResponse.json();

    if (revenueCatPendingResponse.status >= 400) {
      //console.log("RevenueCatError: ", revenueCatPendingResponse);
    } else {
      await setRevenueCatAttributes(user);
    }

    //console.log("RevenueCatResponse: ", revenueCatResponse);
    return revenueCatResponse;
  } catch (e) {
    //console.log("RevenueCatError: ", e);
    return {};
  }
};

export const getEnvUrl = () => {
  if (process.env.REACT_APP_ENV === "dev") {
    return "http://localhost:3000";
  } else if (process.env.REACT_APP_ENV === "beta") {
    return "https://dev-app.bidmyvid.com";
  } else if (process.env.REACT_APP_ENV === "prod") {
    return "https://app.bidmyvid.com";
  }
};
